import React, { useRef, useState } from 'react';
import './Resources.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCirclePlay } from '@fortawesome/free-solid-svg-icons'; 
import CarouselScreen from '../home/carousel';
import Stipulation from './Stipulation';
import { Link } from 'react-router-dom';
import RequestModal from './RequestModal';
import Download from './Download';
import Divorcing from './Divorcing';

library.add(faCirclePlay);


const Resources = () => {
    window.scrollTo(0, 0);
    
    const financialRef = useRef(null);
    const [request, setRequest] = useState(false);
    const [divorce, setDivorce] = useState(false);
    const [real, setReal] = useState(false);

    const requetModal = () => {
        setRequest(true);
    };

    const download = () => {
        setDivorce(true);
    }

    const home  = () => {
        setReal(true);
    }
    const handleClick = () => {
        // For example, open the link in a new tab
        window.open('http://www.Gordon-RealEstate.com', '_blank');
    };

    const finical = () => {
        // Scroll to the Financial component using the ref
        if (financialRef.current) {
            financialRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    return (
        <section>
            <div className="resource">
                <div className="text-overlay">
                    <h1 className="center-text">Client Resource Center</h1>
                    <p className='mt-3'>Knowledge is Power</p>
                    <div className="container resource-cards">
                        <div className="row">
                            <div className="col-md-4 col-sm-12">
                                <div className="card">
                                    <p>Real Property Documents</p>
                                    <div className="call-btn mt-3">
                                        <Link to="" className="schedule-call-button1" onClick={requetModal}>Request
                                        </Link>
                                    </div>
                                </div>
                            </div>                        
                            <div className="col-md-4 col-sm-12">
                            <div className="card">
                                <p>What is my home worth? </p>
                                <div className="call-btn mt-3">
                                    <Link to="" className="schedule-call-button1" onClick={home }>Visit
                                    </Link>
                                </div>
                            </div>
                        </div>
                        </div>

                        <div className="row mt-5">
                            <div className="col-md-4 col-sm-12">
                                <div className="card">
                                    <p>Homes for Sale</p>
                                    <div className="call-btn mt-3">
                                        <Link to="" className="schedule-call-button1" onClick={handleClick}>Search
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <div className="card"> 
                                    <p>Home Prep Program</p>
                                    <div className="call-btn mt-3">
                                        <Link to="/home-prep-program" className="schedule-call-button1">Learn More
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-sm-12 text-center">
                                <div className="informed">
                                    <h3>Stay Informed</h3>
                                    <p>Keep in the know through every step of the listing and sales process. SRG will also keep attorneys informed as well.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        <div className="row">
                            <div className="col-md-9 col-sm-12 text-center">
                                <div className="access-content mt-2">
                                    <h3>Access Resources</h3>
                                    <p>From property documents and appraisals to financial calculators and a guide to dividing your house, access what you need right here.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-sm-12 text-center">
                                <div className="expert">
                                    <h3>Refer an Expert</h3>
                                    <p>Working with an unbiased, compassionate Realtor with deep experience navigating real estate in divorce protects your best interests.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
             
            <CarouselScreen />
            <Divorcing  real={real} setReal={setReal} />
            <RequestModal request={request} setRequest={setRequest} />
            <Download divorce={divorce} setDivorce={setDivorce} />
        </section>
    );
}

export default Resources;
