import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { FaArrowUp, FaArrowDown, FaArrowRight } from 'react-icons/fa';
import './../../common/header/header.css';
import './RequestModal.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const RequestModal = ({ request, setRequest }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [name, setName] = useState('');
  const [is_attorney, setIs_attorney] = useState('');
  const [attorney_name, setAttorney_name] = useState('');
  const [property_type, setProperty_type] = useState('');
  const [address, setAddress] = useState('');
  const [documents, setDocuments] = useState('');
  const [email, setEmail] = useState('');
  const [downloadIntro, setDownloadIntro] = useState(true);
  const [nameError, setNameError] = useState('');
  const [selectedAttorney, setSelectedAttorney] = useState(null);
  const [is_attorneyError, setIs_attorneyError] = useState('');
  const [attorney_nameError, setAttorney_nameError] = useState('');
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [property_typeError, setProperty_typeError] = useState('');
  const [addressError, setAddressError] = useState('');
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [documentsError, setDocumentsError] = useState('');
  const [emailError, setEmailError] = useState('');

  const steps = [
    { title: '1', content: "What's your name?", input: name, setInput: setName },
    { title: '2', content: "Do you have an attorney?", input: is_attorney, setInput: setIs_attorney },
    { title: '3', content: "Please provide your attorney’s name", input: attorney_name, setInput: setAttorney_name },
    { title: '4', content: "Property Type", input: property_type, setInput: setProperty_type },
    { title: '5', content: "Street Address, City, State and Zip Code", input: address, setInput: setAddress },
    { title: '6', content: "Complimentary Documents Requested", input: documents, setInput: setDocuments },
    { title: '7', content: "What email address should we send this to?", input: email, setInput: setEmail },
  ];

  const handleCloseModal = () => {
    setRequest(false);
  };

  const handlePreviousStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleNextStep = () => {
    if (currentStep === 1 && name.length < 3) {
      setNameError('Please enter at least 3 characters for your name.');
      return;
    }
    setNameError('');
    if (currentStep === 2 && !selectedAttorney) {
      setIs_attorneyError('Please select at least one option.');
      return;
    }
    setIs_attorneyError('');
    if (currentStep === 3 && attorney_name.length < 3) {
      setAttorney_nameError('Please enter at least 3 characters for your attorney’s name.');
      return;
    }
    setAttorney_nameError('');
    if (currentStep === 4 && !selectedProperty) {
      setProperty_typeError('Please select at least one option.');
      return;
    }
    setProperty_typeError('');
    if (currentStep === 5 && address.length < 3) {
      setAddressError('Please enter at least 3 characters for the street address.');
      return;
    }
    setAddressError('');
    if (currentStep === 6 && selectedDocuments.length === 0) {
      setDocumentsError('Please select at least one option.');
      return;
    }
    setDocumentsError('');
    if (currentStep === 7 && email.length < 3) {
      setEmailError('Please enter at least 3 characters for the email address.');
      return;
    }
    setEmailError('');
    if (currentStep === 2) {
      setIs_attorney(selectedAttorney);
    }
    if (currentStep === 4) {
      setProperty_type(selectedProperty);
    }

    if (currentStep < 7) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleButtonClick = (value) => {
    if (currentStep !== 2 && currentStep !== 4 && currentStep !== 6) {
      if (currentStep === 5) {
        setAddress(value);
      } else if (currentStep === 6) {
        setDocuments(value);
      }
      handleNextStep();
    } else {
      if (currentStep === 2) {
        setSelectedAttorney(value);
      } else if (currentStep === 4) {
        setSelectedProperty(value);
      } else if (currentStep === 6) {
        const updatedOptions = [...selectedDocuments];
        const index = updatedOptions.indexOf(value);
        const selectedValue = getSelectedValue(value);
        if (index === -1) {
          updatedOptions.push(selectedValue);
        } else {
          updatedOptions.splice(index, 1);
        }
        setSelectedDocuments(updatedOptions);
      }
      if (currentStep === 7 && validateEmail(email)) {
        setEmailError('');
      } else {
        setEmailError('Please enter a valid email address.');
      }
    };
  }

  const getSelectedValue = (value) => {
    switch (value) {
      case 'A':
        return 'A. Deed(s) of Trust';
      case 'B':
        return 'B. Grant Deed';
      case 'C':
        return 'C. Legal Description';
      case 'D':
        return 'D. Chain of Title';
      case 'E':
        return 'E. Property Profile';
      default:
        return value;
    }
  }

  const handleSubmit = () => {
    if (currentStep === 7 && !emailError) {
      // Format selectedDocuments array without backslashes
      const formattedDocuments = selectedDocuments.map(document => getSelectedValue(document));

      const requestData = {
        name: name,
        is_attorney: is_attorney,
        attorney_name: attorney_name,
        property_type: property_type,
        address: address,
        documents: formattedDocuments,
        email: email,
      };

      fetch('https://utahdivorcerealestate.com/gordon/api/real-property-docs', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Auth-Token': '0H2jTtterGuVLooOnwRbfHslly94SKnaLbBwfp6VxvIFZRt5sG0byyTqMyHNI0po',
        },
        body: JSON.stringify(requestData),
      })
        .then(response => response.json())
        .then(data => {
          console.log('API Response:', data);
          handleCloseModal();
          toast.success('Data submitted successfully!', { autoClose: 3000 });
        })
        .catch(error => {
          console.error('Error during API request:', error);
          toast.error('Error submitting data. Please try again.', { autoClose: 3000 });
        });
    } else {
      handleNextStep();
    }
  };


  const handleGetStarted = () => {
    setDownloadIntro(false);
  };

  useEffect(() => {
    if (!request) {
      setDownloadIntro(true);
      setCurrentStep(1);
      setName('');
      setIs_attorney('');
      setAttorney_name('');
      setProperty_type('');
      setAddress('');
      setDocuments('');
      setEmail('');
      setSelectedAttorney(null);
      setSelectedProperty(null);
      setSelectedDocuments([]);
      setEmailError('');
    }
  }, [request]);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  useEffect(() => {
    setEmailError(validateEmail(email) ? '' : 'Invalid email address');
  }, [email]);
  const handleClose =()=> {
    handleCloseModal();
  }
  return (
    <div>
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <div className="modail-container text-center">
        <Modal show={request} className="modail-container" onHide={handleCloseModal} size="lg" centered>
        <div className='text-end'>
        <button   onClick={handleClose}>X</button>
        </div>
        <Modal.Body>
            {downloadIntro ? (
              <div className='oder-modal-content'>
                <h3 className='text-center Laurel-starrkks'>Appoint Joe Gordon </h3>
                <p className='mt-3 appointtedstarkss'> Whether Joe Gordon has been appointed to your case, or you
                  are working in a case that you’d like to have him appointed, if you
                  provide the following information, we can get you what you need. </p>
                <p className="appointtedstarkss">
                  Click below to get started:
                </p>
                <div className="sttarttbtnnn">
                  <Button className='get-started mt-5 butttoonnclickk' onClick={handleGetStarted}>
                    Start
                  </Button>
                </div>
              </div>
            ) : (
              <Form>
                <Form.Group>
                  <span className="fontdesignings">{currentStep}</span>
                  <FaArrowRight className="step-arrow arrowdesignss" />
                  <Form.Label className='form-label'>{steps[currentStep - 1].content}</Form.Label>

                  {currentStep === 2 && (
                    <div className='modals-btn '>
                      <button
                        type="button"
                        className={`modal-btn yesssbtnnn ${selectedAttorney === 'Yes' ? 'selected' : ''}`}
                        style={{ backgroundColor: selectedAttorney === 'Yes' ? '#C3AA91' : '' }}
                        onClick={() => handleButtonClick('Yes')}
                      >
                        Yes
                      </button>
                      <button
                        type="button"
                        className={`modal-btn  yesssbtnnn  ${selectedAttorney === 'No' ? 'selected' : ''}`}
                        style={{ backgroundColor: selectedAttorney === 'No' ? '#C3AA91' : '' }}
                        onClick={() => handleButtonClick('No')}
                      >
                        No
                      </button>
                    </div>
                  )}

                  {currentStep === 4 && (
                    <div className='modals-btn'>
                      <button
                        type="button"
                        className={`modal-btn residentialbttn ${selectedProperty === 'Residential' ? 'selected' : ''}`}
                        style={{ backgroundColor: selectedProperty === 'Residential' ? '#C3AA91' : '' }}
                        onClick={() => handleButtonClick('Residential')}
                      >
                        Residential
                      </button>
                      <button
                        type="button"
                        className={`modal-btn residentialbttn ${selectedProperty === 'Commercial' ? 'selected' : ''}`}
                        style={{ backgroundColor: selectedProperty === 'Commercial' ? '#C3AA91' : '' }}
                        onClick={() => handleButtonClick('Commercial')}
                      >
                        Commercial
                      </button>
                    </div>
                  )}

                  {currentStep === 6 && (
                    <div className='modals-btn'>
                      <Button
                        className={`modal-btn deaaddoftrustbtn ${selectedDocuments.includes('A. Deed(s) of Trust') ? 'selected' : ''}`}
                        style={{ backgroundColor: selectedDocuments.includes('A. Deed(s) of Trust') ? '#C3AA91' : '' }}
                        onClick={() => handleButtonClick('A')}
                      >
                        A. Deed(s) of Trust
                      </Button>
                      <Button
                        className={`modal-btn deaaddoftrustbtn ${selectedDocuments.includes('B. Grant Deed') ? 'selected' : ''}`}
                        style={{ backgroundColor: selectedDocuments.includes('B. Grant Deed') ? '#C3AA91' : '' }}
                        onClick={() => handleButtonClick('B')}
                      >
                        B. Grant Deed
                      </Button>
                      <Button
                        className={`modal-btn deaaddoftrustbtn ${selectedDocuments.includes('C. Legal Description') ? 'selected' : ''}`}
                        style={{ backgroundColor: selectedDocuments.includes('C. Legal Description') ? '#C3AA91' : '' }}
                        onClick={() => handleButtonClick('C')}
                      >
                        C. Legal Description
                      </Button>
                      <Button
                        className={`modal-btn deaaddoftrustbtn ${selectedDocuments.includes('D. Chain of Title') ? 'selected' : ''}`}
                        style={{ backgroundColor: selectedDocuments.includes('D. Chain of Title') ? '#C3AA91' : '' }}
                        onClick={() => handleButtonClick('D')}
                      >
                        D. Chain of Title
                      </Button>
                      <Button
                        className={`modal-btn deaaddoftrustbtn ${selectedDocuments.includes('E. Property Profile') ? 'selected' : ''}`}
                        style={{ backgroundColor: selectedDocuments.includes('E. Property Profile') ? '#C3AA91' : '' }}
                        onClick={() => handleButtonClick('E')}
                      >
                        E. Property Profile
                      </Button>
                    </div>
                  )}

                  {currentStep === 2 && (
                    <div className="button-row">
                      <button type="button" className='modal-btn modal-ok-btn butttooonnookk' onClick={handleSubmit}>
                        OK
                      </button>
                    </div>
                  )}

                  {currentStep !== 2 && currentStep !== 4 && currentStep !== 6 && currentStep !== 7 && (
                    <div>
                      <Form.Control
                        className='header-form-inputs'
                        type="text"
                        placeholder={`Enter your ${currentStep === 1 ? 'name' : currentStep === 3 ? 'attorney\'s name' : 'property type'}`}
                        value={steps[currentStep - 1].input}
                        onChange={(e) => {
                          steps[currentStep - 1].setInput(e.target.value);
                          // Clear step 1, step 3, or step 5 error when input changes
                          if (currentStep === 1) {
                            setNameError('');
                          } else if (currentStep === 3) {
                            setAttorney_nameError('');
                          } else if (currentStep === 5) {
                            setAddressError('');
                          }
                        }}
                      />
                      {/* Display step 1, step 3, or step 5 error message */}
                      {currentStep === 1 && nameError && <p className="error-message">{nameError}</p>}
                      {currentStep === 3 && attorney_nameError && <p className="error-message">{attorney_nameError}</p>}
                      {currentStep === 5 && addressError && <p className="error-message">{addressError}</p>}
                    </div>
                  )}

                  {currentStep === 7 && (
                    <div>
                      <Form.Control
                        className='header-form-inputs'
                        type="text"
                        placeholder="Enter your email address"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          // Validate email when step7Input changes
                          setEmailError(validateEmail(e.target.value) ? '' : 'Invalid email address');
                        }}
                      />
                      {emailError && <p className="error-message">{emailError}</p>}
                    </div>
                  )}

                  {/* Display step 2, step 4, or step 6 error message */}
                  {currentStep === 2 && is_attorneyError && <p className="error-message">{is_attorneyError}</p>}
                  {currentStep === 4 && property_typeError && <p className="error-message">{property_typeError}</p>}
                  {currentStep === 6 && documentsError && <p className="error-message">{documentsError}</p>}
                </Form.Group>

                <div className="button-block">
                  {(currentStep !== 7 && currentStep !== 2) && (
                    <button type="button" className='modal-btn modal-ok-btn butttooonnookk' onClick={handleSubmit}>
                      OK
                    </button>
                  )}
                  {currentStep === 7 && (
                    <Button
                      className='modal-btn modal-ok-btn btnnnsuubbmmitt'
                      onClick={handleSubmit}
                      disabled={emailError !== '' || email.length < 3} // Add additional conditions as needed
                    >
                      Submit
                    </Button>
                  )}
                </div>

              </Form>
            )}
          </Modal.Body>

          {!downloadIntro && (
            <Modal.Footer className="p-2">
              <Button onClick={handlePreviousStep} className={`footer-icon ${currentStep > 1 ? 'animate-up' : ''}`} disabled={currentStep === 1 ? true : ""}>
                <FaArrowUp />
              </Button>
              <Button onClick={handleNextStep} className="footer-icon" disabled={currentStep === 7 ? true : ""}>
                <FaArrowDown />
              </Button>
            </Modal.Footer>
          )}
        </Modal>
      </div>
    </div>
  );



}

export default RequestModal;
