import React, { useState } from "react";
import { Col } from "react-bootstrap";
import Home from "../../assets/images/home-b.png";
import Modals from "../../common/header/Modals";

const Businesssection = () => {

  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  return (
    <div className="business-section-box">
      <div>
        <Col sm={3} md={3}>
          <div className="business-section-small-img">
            <img src={Home} alt="about-us" className="img-fluid" />
          </div>
        </Col>
        <Col sm={4} md={4}>
          <div className="p-20 text-left">
            <h5 className="business-text">THIS ISN'T BUSINESS AS USUAL.</h5>
            <p className="business-sub-text mt-4">
              The real estate portion of your case deserves an expert, the same
              as every other aspect of your case. Without a specialized expert:
            </p>
            <ul className="business-text-list">
              <li>Conflict can escalate</li>
              <li>Attorney fees can get out of hand</li>
              <li>Your well-being is left in peril</li>
            </ul>
            <p className="business-text-2">
              Not because they mean to cause harm, but because they don’t know
              any other way, non-specialized Realtors apply the traditional
              processes of real estate in divorce transactions— and that doesn’t
              work.
            </p>

            <div className="access-button1 text-center mt-3">
              <button className="btn-access" onClick={openModal}>Schedule a Call</button>
            </div>
          </div>
        </Col>
        <Col sm={5} md={5}>
          <div className="business-section-large-img"></div>
        </Col>
      </div>
      <Modals showModal={showModal} setShowModal={setShowModal} />
    </div>
  );
};
export default Businesssection;
