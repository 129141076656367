import React from 'react'
import "./Stipulation.css";
import stipulation from "../../assets/images/joe.jpg";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Stipulation = () => {

    const pdfUrl = 'https://utahdivorcerealestate.com/gordon/pdf/JOE-GORDON-CV.pdf';

    const handleDownloadPDF = () => {
      try {
        // Create a temporary anchor element
        const downloadAnchor = document.createElement('a');
        downloadAnchor.href = pdfUrl;
        downloadAnchor.download = 'JOE-GORDON-CV.pdf';
        downloadAnchor.target = '_blank'; // Open in a new tab
        // Trigger a click on the anchor to start the download
        document.body.appendChild(downloadAnchor);
        downloadAnchor.click();
        document.body.removeChild(downloadAnchor);
  
        toast.success('PDF is downloaded !');
      } catch (error) {
        console.error('Error opening PDF:', error);
        toast.error('Error opening PDF. Please try again.');
      }
    };

    return (
        <section>
            <div className="stipulation">
                <div className="container1">
                    <div className='laurel-starks' >
                        <div className="row">
                            <div className="col-md-5 col-sm-12">
                                <div className="stipulation-img">
                                    <img src={stipulation} alt='stipulation' />
                                </div>
                            </div>
                            <div className="col-md-5 col-sm-12 p-4 ">
                                <div className="estate">
                                    <h1 className='mt-3'>Stipulation &amp; CV</h1>
                                    <div className="starks">
                                        <p className='mt-2'>
                                            <b>  Joe Gordon is a recognized expert in divorce real estate, often regarded by his peers as one of the foremost authorities in on the niche in Utah. As an active Realtor and now Broker for 12 years, he has maintained his status as a top 1% producer in Utah for the past 5 years. </b>
                                        </p>
                                    </div>
                                    <div className="witness">
                                        <p className='mt-3'>
                                            Mr. Gordon is a trained expert witness and trained neutral in family law cases involving real estate matters. In addition to facilitating the sale of real property, he advises and consults in complex cases and provides fair market valuations.
                                        </p>
                                    </div>
                                 
                                    <div className="access-btns" style={{ display: "flex" }}>
                                        <div className="access-btn mt-5">
                                            <button className="access-button" onClick={handleDownloadPDF} >Access CV</button>
                                        </div>
                                        <ToastContainer />
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Stipulation;