import React from "react";
import { Row, Col } from "react-bootstrap";

// import img from "../../assets/images/Rectangle 129.png";

const data = [
  { text: "Our listings sell 4X faster than the average house." },
  { text: "We sell 99% of the listings we take" },
  { text: "Our business is 100% referral- based" },
  { text: "We sell 400% more listings than the average agent." },
];

const Successstories = () => {
  return (
    <div className="success-container">
      <div className="container1 success-row-box">
      <div className="success-stroy" >
      <Col sm={4} md={4} className="success-blog-box">
      <p className="success-heading"> Our<br /> Success Is Your<br /> Success.</p>
    </Col>
    <Col sm={4} md={8}>
      <Row className="">
        {data.map((item, index) => {
          return (
            <Col sm={3} md={3} key={index} className="success-light-theme">
              <p className="success-text mt-5"  >{item.text}</p>
            </Col>
          );
        })}
      </Row>
    </Col>
      </div>
       
      </div>
    </div>
  );
};
export default Successstories;
