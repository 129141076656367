
import { BrowserRouter, Route, Routes } from "react-router-dom";
import './App.css';
import './style.css'
import Home from "./components/home/home"
import Header from './common/header/header'
import Footer from './common/footer/footer'
import AboutUs from './components/AboutUs/AboutUs'
import Program from "./components/Advance/Program";
import Resources from "./components/Resources/Resources";
import Attorney from "./components/Attorney-Resources/attorney-Resources";
import NotFound from "./components/NotFound";


function App() {
  return (
    <div className="App">
      <BrowserRouter >
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/attorney-Resources" element={<Attorney />} />
          <Route path="/access-resources" element={<Resources />} />
          <Route path="/home-prep-program" element={<Program />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
