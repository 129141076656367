import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { FaArrowUp, FaArrowDown, FaArrowRight } from 'react-icons/fa';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import './header.css';
import './Modals.css';
import 'react-phone-number-input/style.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Modals = ({ showModal, setShowModal }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    topics: '',
  });
  const [inputErrors, setInputErrors] = useState({
    name: '',
    email: '',
    phone: '',
    topics: '',
  });
  const [requestIntro, setRequestIntro] = useState(true);
  const [isInputBlurred, setIsInputBlurred] = useState(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState('US');
  const [showCountryCode, setShowCountryCode] = useState(false);
  const stepContents = [
    "What's your name?",
    "What's your email address?",
    "What's the phone number for us to contact you?",
    "What would you like to discuss on a call?",
  ];

  const handleApiCall = async () => {
    const apiUrl = 'https://utahdivorcerealestate.com/gordon/api/schedulale-call';
    const authToken = '0H2jTtterGuVLooOnwRbfHslly94SKnaLbBwfp6VxvIFZRt5sG0byyTqMyHNI0po';
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Auth-Token': authToken,
        },
        body: JSON.stringify({
          name: formData.name,
          email: formData.email,
          phone: formData.phone,
          topics: formData.topics,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log('API call successful', data);
        toast.success('Form submitted successfully!', { autoClose: 3000 });
      } else {
        console.error('API call failed', response.status, response.statusText);
        toast.error('Failed to submit form. Please try again.', { autoClose: 3000 });
      }
    } catch (error) {
      console.error('Error during API call', error);
      toast.error('Error submitting form. Please try again.', { autoClose: 3000 });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("fdhgdfgjdfjkgkl")
    if (currentStep < 4) {
      setCurrentStep(currentStep + 1);
    } else {
      await handleApiCall();
      // Close the modal after the API call
      handleCloseModal();
    }
  };
  const handleClose = () => {
    handleCloseModal();
  }
  const handleBlur = () => {
    setIsInputBlurred(true);
  };

  const handleFocus = () => {
    setIsInputBlurred(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleOpenModal = () => {
    setFormData({
      name: '',
      email: '',
      phone: '',
      topics: '',
    });
    setInputErrors({
      name: '',
      email: '',
      phone: '',
      topics: '',
    });
    setCurrentStep(1);
  };

  const handlePreviousStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleGetStarted = () => {
    setRequestIntro(false);
  };

  const handleNextStep = (event) => {
    event.preventDefault();
    // Validation 
    switch (currentStep) {
      case 1:
        if (formData.name.length < 3) {
          setInputErrors((prevErrors) => ({
            ...prevErrors,
            name: 'At least 3 characters are required for the name.',
          }));
          return;
        }
        break;
      case 2:
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
          setInputErrors((prevErrors) => ({
            ...prevErrors,
            email: 'Please enter a valid email address.',
          }));
          return;
        }
        break;
      case 3:
        if (!formData.phone || formData.phone.length < 10 || formData.phone.length > 13) {
          setInputErrors((prevErrors) => ({
            ...prevErrors,
            phone: 'Phone number must be  10 digit.',
          }));
          return;
        }
        break;
        case 4:
          if (formData.topics.trim().split(/\s+/).length < 1) {
            setInputErrors((prevErrors) => ({
              ...prevErrors,
              topics: 'At least one word is required for the discussion topic.',
            }));
            return;
          } else {
            setInputErrors((prevErrors) => ({
              ...prevErrors,
              topics: '',
            }));
          }
          break;
        
        
  
      default:
        break;
    }
    setInputErrors((prevErrors) => ({ ...prevErrors, [Object.keys(prevErrors)[currentStep - 1]]: '' }));
    if (currentStep < 4) {
      setCurrentStep(currentStep + 1);
    } else {
      const hasErrors = Object.values(inputErrors).some((error) => error !== '');
      if (!hasErrors) {
        handleSubmit(event);
      }
    }
  };

  useEffect(() => {
    if (showModal) {
      handleOpenModal();
    }
  }, [showModal]);

  return (
    <div>
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <div className="modal-container text-center">
        <Modal show={showModal} className="modail-container" onHide={handleCloseModal} size="lg" centered>
          <div className='text-end'>
            <button onClick={handleClose}>X</button>
          </div>
          <Modal.Body>
            {requestIntro ? (
              <div className='oder-modal-content'>
                <p className='mt-4 speakinglook'> We look forward to speaking with you! </p>
                <p className='speakinglook'>To best prepare for our call, </p>
                <p className='speakinglook'>please take a minute to answer a few questions.   </p>
                {/*  <p className='speakinglook'>Talk soon!”</p> */}
                <div className="ggeetstarted">
                  <button className='get-started mt-5 startsss' onClick={handleGetStarted}>
                    Get Started
                  </button>
                </div>
              </div>
            ) : (
              <Form>
                <Form.Group>
                  <span className="fontdesignings">{currentStep}</span>
                  <FaArrowRight className="step-arrow arrowdesignss" />
                  <Form.Label className='form-label labellinputt'>{stepContents[currentStep - 1]}</Form.Label>
                  {currentStep === 3 ? (
                    <>
                      <div className="selected-country-code">
                        {showCountryCode && `+${PhoneInput.getCountryCallingCode(selectedCountryCode)}`}
                      </div>

                      <PhoneInput
                        style={{ marginRight: '10px' }}
                        placeholder="Enter Phone Number"
                        value={formData.phone}
                        onChange={(value) => {
                          setFormData({ ...formData, phone: value });
                          // Clear phone input error when input changes
                          setInputErrors((prevErrors) => ({ ...prevErrors, phone: '' }));
                        }}
                        defaultCountry="US"
                        className={`header-form-inputs ${inputErrors.phone && 'is-invalid'}`}
                      />
                      {inputErrors.phone && <div className="invalid-feedback">{inputErrors.phone}</div>}
                    </>
                  ) : (
                    <div>
                      <Form.Control
                        className={`yourrnameee ${inputErrors.name && 'is-invalid'}`}
                        type="text"
                        placeholder={`Enter your ${currentStep === 1 ? 'name' : currentStep === 2 ? 'email address' : 'discussion topic'}`}
                        value={formData[Object.keys(formData)[currentStep - 1]]}
                        onChange={(e) => setFormData({ ...formData, [Object.keys(formData)[currentStep - 1]]: e.target.value })}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                      />
                      {inputErrors.name && <div className="invalid-feedback">{inputErrors.name}</div>}
                      {currentStep === 2 && inputErrors.email && <div className="">{inputErrors.email}</div>}
                      {currentStep === 4 && inputErrors.topics && <div className="invalid-feedback">{inputErrors.topics}</div>}
                    </div>
                  )}
                </Form.Group>
                {currentStep !== 4 && (
                  <button className='modal-btn modal-ok-btn nameokbtnnn' onClick={(e) => handleNextStep(e)}>
                    Ok
                  </button>
                )}
                {currentStep === 4 && (
                  <div>
                  <button className='modal-btn modal-ok-btn btnnhandlesubmitt' onClick={handleSubmit} disabled={inputErrors.topics !== ''}>
                  Submit
                </button>
                
                  </div>
                )}
              </Form>
            )}
          </Modal.Body>
          {!requestIntro && (
            <Modal.Footer className="p-2">
              <Button onClick={handlePreviousStep} className={`footer-icon ${currentStep > 1 ? 'animate-up' : ''}`} disabled={currentStep === 1 ? true : ""}>
                <FaArrowUp />
              </Button>
              <Button onClick={handleNextStep} className="footer-icon" disabled={currentStep === 4 ? true : ""}>
                <FaArrowDown />
              </Button>
            </Modal.Footer>
          )}
        </Modal>
      </div>
    </div>
  );
};

export default Modals;
