import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import '../../common/header/header.css';
import './Download.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Download = ({ divorce, setDivorce }) => {
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        heard: '',
        role: '',
        help: '',
    });

    const [validationErrors, setValidationErrors] = useState({
        first_name: '',
        last_name: '',
        email: '',
        heard: '',
        role: '',
        help: '',
    });

    const handleInputChange = (fieldName, value) => {
        let error = '';
        // Validate first name and last name (at least 3 characters)
        if ((fieldName === 'first_name' || fieldName === 'last_name') && value.length < 3) {
            error = `${fieldName} must have at least 3 characters.`;
        } else if (fieldName === 'email') {
            // Validate email with regex
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(value.trim())) {
                error = 'Enter a valid email address.';
            }
        } else if (fieldName === 'heard' && value.length < 15) {
            error = 'Enter at least 10 characters.';
        } else if (fieldName === 'role' && value.length < 10) {
            error = 'Enter at least 10 characters.';
        } else if (fieldName === 'help' && value.length < 20) {
            error = 'Enter at least 20 characters.';
        }
        setValidationErrors({ ...validationErrors, [fieldName]: error });
        setFormData({ ...formData, [fieldName]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (
            formData.first_name.length < 3 ||
            formData.last_name.length < 3 ||
            !validateEmail(formData.email) ||
            formData.heard.length < 10 ||
            formData.role.length < 10 ||
            formData.help.length < 20
        ) {
            return;
        }
        const requestData = {
            first_name: formData.first_name,
            last_name: formData.last_name,
            email: formData.email,
            heard: formData.heard,
            role: formData.role,
            help: formData.help,
        };
        try {
            const response = await fetch('https://utahdivorcerealestate.com/gordon/api/handbook-download', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Auth-Token': '0H2jTtterGuVLooOnwRbfHslly94SKnaLbBwfp6VxvIFZRt5sG0byyTqMyHNI0po',
                },
                body: JSON.stringify(requestData),
            });
            if (response.ok) {
                setDivorce(false);
                setFormData({
                    first_name: '',
                    last_name: '',
                    email: '',
                    heard: '',
                    role: '',
                    help: '',
                });
                setValidationErrors({
                    first_name: '',
                    last_name: '',
                    email: '',
                    heard: '',
                    role: '',
                    help: '',
                });
                setDivorce(false);
                toast.success('Data submitted successfully!', { autoClose: 3000 });
            } else {
                console.error('Error submitting the form:', response.statusText);
                toast.error('Error submitting data. Please try again.', { autoClose: 3000 });
            }
        } catch (error) {
            console.error('Error submitting the form:', error.message);
            toast.error('Error submitting data. Please try again.', { autoClose: 3000 });
        }
    };
    const validateEmail = (email) => {
        // Validate email with regex
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email.trim());
    };


    const handleClose =()=> {
        setDivorce(false);
      }
    return (
        <div>

        <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <div className="modal-container text-center">
                <Modal show={divorce} className="modail-container" onHide={() => setDivorce(false)} size="lg" centered>
                  <div className='text-end'>
        <button   onClick={handleClose}>X</button>
        </div> 
                <Modal.Body>
                        <Form onSubmit={handleSubmit}>
                            <h2 className='text-center guidedivingform' style={{ fontSize: "38px" }}>Your Guide to Dividing The House Handbook Download</h2>
                            <Form.Group className="row">
                                <Form.Label className='col-md-6 nameeeefirstt'>First Name *</Form.Label>
                                <Form.Label className='col-md-6 nameeeefirstt '>Last Name *</Form.Label>
                                <div className="col-md-6">
                                    <Form.Control
                                        className='header-form-inputs firrssttnammee'
                                        type="text"
                                        placeholder="Enter your first name"
                                        value={formData.first_name}
                                        onChange={(e) => handleInputChange('first_name', e.target.value)}
                                        isInvalid={validationErrors.first_name !== ''}
                                        required={!formData.first_name.trim() && validationErrors.first_name === ''}
                                    />
                                    <Form.Control.Feedback className="validationnamesss" type="invalid">{validationErrors.first_name || 'First Name is required.'}</Form.Control.Feedback>
                                </div>
                                <div className="col-md-6">
                                    <Form.Control
                                        className='header-form-inputs firrssttnammee'
                                        type="text"
                                        placeholder="Enter your last name"
                                        value={formData.last_name}
                                        onChange={(e) => handleInputChange('last_name', e.target.value)}
                                        isInvalid={validationErrors.last_name !== ''}
                                        required={!formData.last_name.trim() && validationErrors.last_name === ''}
                                    />
                                    <Form.Control.Feedback className="validationnamesss" type="invalid">{validationErrors.last_name || 'Last Name is required.'}</Form.Control.Feedback>
                                </div>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className="labellemailll">Email *</Form.Label>
                                <Form.Control
                                    className='header-form-inputs emaillinputt'
                                    type="email"
                                    placeholder="Enter your email"
                                    value={formData.email}
                                    onChange={(e) => handleInputChange('email', e.target.value)}
                                    isInvalid={validationErrors.email !== ''}
                                    required={!formData.email.trim() && validationErrors.email === ''}
                                />
                                <Form.Control.Feedback className="validationnamesss" type="invalid">{validationErrors.email || 'Enter a valid email address.'}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className="labellemailll">How did you hear about us? *</Form.Label>
                                <Form.Control
                                    className='header-form-inputs heardabouttt'
                                    type="text"
                                    placeholder="Enter how you heard about us"
                                    value={formData.heard}
                                    onChange={(e) => handleInputChange('heard', e.target.value)}
                                    isInvalid={validationErrors.heard !== ''}
                                    required={!formData.heard.trim() && validationErrors.heard === ''}
                                />
                                <Form.Control.Feedback className="validationnamesss" type="invalid">{validationErrors.heard || 'Enter at least 10 characters.'}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className="labellemailll">Are you an attorney, client, other (please specify) *</Form.Label>
                                <Form.Control
                                    className='header-form-inputs youurrrolee'
                                    type="text"
                                    placeholder="Enter your role"
                                    value={formData.role}
                                    onChange={(e) => handleInputChange('role', e.target.value)}
                                    isInvalid={validationErrors.role !== ''}
                                    required={!formData.role.trim() && validationErrors.role === ''}
                                />
                                <Form.Control.Feedback className="validationnamesss" type="invalid">{validationErrors.role || 'Enter at least 10 characters.'}</Form.Control.Feedback>
                            </Form.Group>                            <Form.Group>
                                <Form.Label className="labellemailll">How can we help you? *</Form.Label>
                                <Form.Control
                                    className='header-form-inputs cannheelpp'
                                    type="text"
                                    placeholder="Enter how we can help you"
                                    value={formData.help}
                                    onChange={(e) => handleInputChange('help', e.target.value)}
                                    isInvalid={validationErrors.help !== ''}
                                    required={!formData.help.trim() && validationErrors.help === ''}
                                />
                                <Form.Control.Feedback className="validationnamesss" type="invalid">{validationErrors.help || 'Enter at least 20 characters.'}</Form.Control.Feedback>
                            </Form.Group>
                            <Button type="submit" className='modal-btn modal-ok-btn suubbmittbttnn' style={{ background: 'black', color: 'white' }}>
                                Submit
                            </Button>
                        </Form>
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    );
}

export default Download;
