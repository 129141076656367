
import React from "react";
import { Row, Col } from "react-bootstrap";
import homeimg from "../../assets/images/Rectangle 126.png";
import { Link } from "react-router-dom";


const Teamsection = () => {
  return (
    <div className="team-section-container">
      <div className="container111 text-center">
        <h5 className="team-text-heading">How It All Began...</h5>
        <p className="team-text-sub-heading mt-3">

          7 years ago I personally went through a challenging Divorce and quickly realized that there was a significant need for Divorce Real Estate Experts. At the time I had worked as a real estate agent for years and didn’t know of a single Realtor in our market that specialized in working in the family law space.
        </p>
        <Row>
          <Col sm={6} md={5} className="mt-3">
            <img src={homeimg} className="home-img" alt="homeimg" />
          </Col>
          <Col sm={6} md={7}>
            <p className="regular-font color mt-3">

              Divorce is complicated enough without real estate. Oftentimes divorcing homeowners have friends or family members that have a real estate license but that will have a challenging time being a neutral agent for both sides. Add in a high conflict situation and the complexity skyrockets.
              Agents working with divorcing homeowners must be familiar with family law attorneys, mediators and judges and their common practices. They also must be trained to read and follow court orders, manage high conflict cases and most importantly, to be an unbiased and neutral 3rd party. </p>
            <p className="regular-font color mt-4">     For this specific training, I attended the Ilumni Institute and became a Certified Divorce Real Estate Expert (CDRE). This institution is the #1 training program in the U.S. for Real Estate Agents specializing in the family law space. Currently we are affiliated with 170 of the top Family Law attorneys and Mediators in Utah and 50% of the homes that we sell in our Brokerage are part of divorce proceedings. </p>

            { /*<p className="regular-font color">All of this to say: My team and I have our clients’ backs, and if you choose to work with us, we’ll be fierce advocates for you as you Real Estate Services for Divorcing Homeowners.</p> */}

            <p className="regular-font color">We are here to help and look forward to working with you!</p>
            <p className="signature-text mt-3">Joe Gordon </p>
            <div className="team-meeet text-center mt-3">
              <Link to="/about-us"  >  <button className="meet-team-btn">
                MEET THE TEAM
              </button>
              </Link>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Teamsection;
