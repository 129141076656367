import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { FaArrowUp, FaArrowDown, FaArrowRight } from 'react-icons/fa';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import '../../common/header/header.css';
import "./Getstarted.css";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const GetStarted = ({ showModal, setShowModal }) => {
  const [showIntro, setShowIntro] = useState(true);
  const [currentStep, setCurrentStep] = useState(1);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [firmName, setFirmName] = useState('');
  const [courtOrder, setCourtOrder] = useState(null);
  const [error, setError] = useState('');

  const steps = [
    { title: '1', content: "What's your name?", input: name, setInput: setName, error: null },
    { title: '2', content: "What is your phone number?", input: phone, setInput: setPhone, error: null },
    { title: '3', content: "What is your firm’s name?", input: firmName, setInput: setFirmName, error: null },
    { title: '4', content: "In there a court order appointing Mr. Gordon?", input: null, setInput: () => { }, error: null },
  ];

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handlePreviousStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleNextStep = () => {
    if (validateStep()) {
      if (currentStep < 4) {
        setCurrentStep(currentStep + 1);
        setError(''); // Clear any previous errors when moving to the next step
      }
    }
  };
  
  const handleYesClick = () => {
    setCourtOrder('Yes');
  };

  const handleNoClick = () => {
    setCourtOrder('No');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (validateStep()) {
      if (currentStep < 4) {
        handleNextStep();
      } else {
        const apiUrl = 'https://utahdivorcerealestate.com/gordon/api/appointment';
        const authToken = '0H2jTtterGuVLooOnwRbfHslly94SKnaLbBwfp6VxvIFZRt5sG0byyTqMyHNI0po';

        const requestData = {
          name,
          phone,
          firmName,
          courtOrder,
        };

        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Auth-Token': authToken,
          },
          body: JSON.stringify(requestData),
        };

        try {
          const response = await fetch(apiUrl, requestOptions);

          if (response.ok) {
          
            toast.success('Data submitted successfully!', { autoClose: 3000 });
            console.log('Appointment data submitted successfully');
          } else {
            console.error('Error submitting appointment data:', response.statusText);
            toast.error('Error submitting data. Please try again.', { autoClose: 3000 });
          }
        } catch (error) {
          console.error('Error:', error.message);
          toast.error('Error submitting data. Please try again.', { autoClose: 3000 });
        }

        handleCloseModal();
      }
    }
  };
 
  const validateStep = () => {
    switch (currentStep) {
      case 1:
        if (name.trim().length < 3) {
          setError(`${steps[currentStep - 1].content} must be at least 3 characters.`);
          return false;
        }
        break;
    
      case 2:
        // Assuming a valid international phone number should start with a '+' and have at least 10 digits
        const phoneRegex = /^\+(?:[0-9] ?){9,11}[0-9]$/;
        if (!phone || !phoneRegex.test(phone.replace(/[^0-9+]/g, ''))) {
          setError(`Phone number must be a valid  phone number.`);
          return false;
        }
        break;
     
      case 3:
        if (firmName.trim().length < 3) {
          setError(`${steps[currentStep - 1].content} must be at least 3 characters.`);
          return false;
        }
        break;
      case 4:
        if (courtOrder !== 'Yes' && courtOrder !== 'No') {
          setError(`${steps[currentStep - 1].content} is required.`);
          return false;
        }
        break;
      default:
        break;
    }
    setError('');
    return true;
  };

  const handleGetStarted = () => {
    setShowIntro(false);
  };

  useEffect(() => {
    if (!showModal) {
      setShowIntro(true);
      setCurrentStep(1);
      setName('');
      setPhone('');
      setFirmName('');
      setCourtOrder('');
      setError('');
    }
  }, [showModal]);
  const handleClose =()=> {
    handleCloseModal();
  }
  return (
    <div>
    <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <div className="modal-container text-center">
        <Modal show={showModal} className="modail-container" onHide={handleCloseModal} size="lg" centered>
        <div className='text-end'>
        <button   onClick={handleClose}>X</button>
        </div>
           <Modal.Body>
            {showIntro ? (
              <div className='oder-modal-content'>
                <h3 className='text-center appointlaurelll'>
                Appoint Joe Gordon </h3>
                <p className='mt-5 starkksss'>Appoint Joe Gordon / whether Mr. Gordon has been appointed to your case or you are working in a case that you would like to have him appointed…
                </p>
                <p className="starkksss mt-4">
                  Click below to get started:
                </p>
                <div className="starrttbtnnn">
                  <button className='get-started mt-5 bbbttnnnn' onClick={handleGetStarted}>
                    Start
                  </button>
                </div>
              </div>
            ) : (
              <Form onSubmit={handleSubmit}>
                <Form.Group>
                  <span className="fontdesignings">{currentStep}</span>
                  <FaArrowRight className="step-arrow arrowdesignss" />
                  <Form.Label className='form-label'>{steps[currentStep - 1].content}</Form.Label>
                  {currentStep === 2 ? (
                    <PhoneInput
                      style={{ marginRight: '10px' }}
                      placeholder="Enter phone number"
                      value={steps[currentStep - 1].input}
                      onChange={(value) => {
                        steps[currentStep - 1].setInput(value);
                        // Clear step 2 error when input changes
                        setError('');
                      }}
                      defaultCountry="US"
                      className={`header-form-inputs ${error !== '' ? 'is-invalid' : ''}`}
                    />
                  ) : (
                    (currentStep !== 4 && (
                      <Form.Control
                        className={`header-form-inputs ${currentStep === 4 ? 'step4-options' : ''} ${error !== '' ? 'is-invalid' : ''}`}
                        type="text"
                        placeholder={`Enter your ${currentStep === 1 ? 'name' : currentStep === 3 ? 'firm name' : 'discussion topic'}`}
                        value={steps[currentStep - 1].input}
                        onChange={(e) => {
                          steps[currentStep - 1].setInput(e.target.value);
                          // Clear step 1 or step 3 error when input changes
                          setError('');
                        }}
                      />
                    ))
                  )}
                  {error && (
                    <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
                  )}
                </Form.Group>
                {currentStep === 4 && (
                  <div className='modals-btn'>
                    <button className={`modal-btn modal-ok-btn yesbuttonnnn ${courtOrder === 'Yes' ? 'selected' : ''}`} onClick={handleYesClick} disabled={courtOrder === 'Yes'}>
                      Yes
                    </button>
                    <button className={`modal-btn modal-cancel-btn yesbuttonnnn ${courtOrder === 'No' ? 'selected' : ''}`} onClick={handleNoClick} disabled={courtOrder === 'No'}>
                      No
                    </button>
                  </div>
                )}
                <div className="button-block">
                  <button type="submit" className='modal-btn modal-ok-btn butttooonnookk'>
                    {currentStep < 4 ? 'OK' : 'Submit'}
                  </button>
                </div>
              </Form>
            )}
          </Modal.Body>
          {!showIntro && (
            <Modal.Footer className="p-2">
              <Button onClick={handlePreviousStep} className={`footer-icon ${currentStep > 1 ? 'animate-up' : ''}`} disabled={currentStep === 1 ? true : ""}>
                <FaArrowUp />
              </Button>
              <Button onClick={handleNextStep} className="footer-icon" disabled={currentStep === 4 ? true : ""}>
                <FaArrowDown />
              </Button>
            </Modal.Footer>
          )}
        </Modal>
      </div>
    </div>
  );
}

export default GetStarted;
