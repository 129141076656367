import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import "./footer.css";

function Footer() {
  return (
    <div className="footer mt-5">
      <div className="row justify-content-center">
        <div className="col-sm-12 col-md-4 ">
          <div className="footer-links-address">
            <img height={134} width={122} src={logo} alt="footer-img" className="mb-3" />
          </div>
        </div>
        <div className="col-sm-12 col-md-4 ">
          <ul className="footer-links">
            <h5><b>Useful Links</b></h5>
            <li>   <Link to="/about-us" > About Us </Link></li>
            <li> <Link to="/attorney-resources" > Attorney Resources</Link> </li>
            <li>Client   <Link to="/access-resources" >Resources </Link></li>
            <li >  <Link to="/home-prep-program" > Home Prep Program</Link></li>
          </ul>
        </div>
        <div className="col-sm-12 col-md-4">
          <ul className="footer-links">
            <h5><b> Contact Us</b></h5>
            <li>  <p>Gordon Real Estate Group</p>
              <p> 8791 S. Redwood Road #250</p>
              <p>West Jordan, UT </p>
              <p>84088</p>
            </li>
            <li>Phone: <a href="tel:801-577-6304" className="no-decoration">801-577-6304</a></li>
            <li className="mb-5">Email: <a href="mailto:josephgordonhomes@gmail.com" className="no-decoration">josephgordonhomes@gmail.com</a></li>
          </ul>
        </div>
      </div>
      <div className="dark-footer">
        <p>© 2023 Gordon. All Rights Reserved.</p>
      </div>
    </div>
  );
}

export default Footer;
