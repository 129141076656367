import React, { useState, useRef } from 'react';
import "./attorney-Resources.css";
import CarouselScreen from '../home/carousel';
import Stipulation from '../Resources/Stipulation';
import { Link } from 'react-router-dom';
import OrderModals from './OrderModals';
import Request from './Request';
import GetStarted from './GetStarted';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Attorney = () => {
    window.scrollTo(0, 0);
    const [showModal, setShowModal] = useState(false);
    const [property, setProperty] = useState(false);
    const [appraisal, setAppraisal] = useState(false);
    // const [pdfContent, setPdfContent] = useState('');
    // const [showLearningSection, setShowLearningSection] = useState(true);

    const learningSectionRef = useRef(null);
    const OrderModal = () => {
        setAppraisal(true);
    };

    const scrollToLearningSection = () => {
        if (learningSectionRef.current) {
            learningSectionRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const request = () => {
        setProperty(true);
    }
    const started = () => {
        setShowModal(true);
    }

    const redirectToThirdPartyLink = () => {
        const thirdPartyLink = "https://www.rthebar.com/";
        window.open(thirdPartyLink, "_blank");
    };

    const pdfUrl = 'https://utahdivorcerealestate.com/gordon/pdf/JOE-GORDON-CV.pdf';

    const handleDownloadPDF = () => {
        try {
            const downloadAnchor = document.createElement('a');
            downloadAnchor.href = pdfUrl;
            downloadAnchor.download = 'JOE-GORDON-CV.pdf';
            downloadAnchor.target = '_blank';
            document.body.appendChild(downloadAnchor);
            downloadAnchor.click();
            document.body.removeChild(downloadAnchor);
            toast.success('PDF is downloaded !');
        } catch (error) {
            console.error('Error opening PDF:', error);
            toast.error('Error opening PDF. Please try again.');
        }
    };

    return (
        <section>
            <div className="attorney-resource">
                <div className="attorney-text-overlay">
                    <h1 className="center-text">Attorney Resource Center</h1>
                    <p className='mt-3'>We’ve Got You Covered.</p>
                    <div className="container resource-cards">
                        <div className="row">
                            <div className="col-md-4 col-sm-12">
                                <div className="card">
                                    <p>Real Property Documents</p>
                                    <div className="call-btn mt-3">
                                        <Link to="" className="schedule-call-button1" onClick={OrderModal}>Order
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <div className="card">
                                    <p>Property Appraisal</p>
                                    <div className="call-btn mt-3">
                                        <Link to="" className="schedule-call-button1" onClick={request}>Request
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <div className="card">
                                    <p>Appoint Joe Gordon</p>
                                    <div className="call-btn mt-3">
                                        <Link to="" className="schedule-call-button1" onClick={started}>Get Started
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-md-4 col-sm-12">
                                <div className="card">
                                    <p >CLE Learning Center</p>
                                    <div className="call-btn mt-3">
                                        <Link to="" className="schedule-call-button1" onClick={scrollToLearningSection} >Register
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <div className="card">
                                    <p>Home Prep Program</p>
                                    <div className="call-btn mt-3">
                                        <Link to="/home-prep-program" className="schedule-call-button1">Learn more
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <div className="card">
                                    <p>Joe Gordon’s CV</p>
                                    <div className="call-btn mt-3">
                                        <Link to="#" className="schedule-call-button1" onClick={handleDownloadPDF}>
                                            Click here
                                        </Link>
                                        <ToastContainer />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-sm-12 text-center">
                                <div className="informed">
                                    <h3>Stay Informed</h3>
                                    <p className='mt-3'>Through free virtual MCLEs, newsletters and case strategy sessions, you’ll be up to speed on issues that impact your clients.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-sm-12 text-center">
                                <div className="access-resources">
                                    <h3>Access Resources</h3>
                                    <p className='mt-3'>From property documents and appraisals to case-specific analysis, access what you need for your cases right here.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-sm-12 text-center">
                                <div className="expert">
                                    <h3>Refer an Expert</h3>
                                    <p className='mt-3'>Using an experienced, trained neutral with a listing model unique to divorce, will save you time and your clients will be taken care of</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <Stipulation />
            </div>

            <div ref={learningSectionRef} className='learning'>
                <div className='center'>
                    <h1>CLE Learning Center</h1>
                </div>
                <div className='raising mt-4'>
                    <h1 className='mt-5'>Raising The Bar </h1>
                </div>
                <div className='virtual mt-5'>
                    <p><b>What: </b> Virtual CLE’s on a broad range of topics for family law attorneys<br />
                        <b>  When:</b> 4th Wednesday of every month 1:00 P:M – 2:15 P:M MST PT Runs January through October<br />
                        <b>Where:</b>  Online<br />
                        <b>  Who:</b> Hosted by the Ilumni Institute national training organization, the Ilumni® Institute</p>
                    <p className='mt-3'><b> Cost:</b>FREE by using the code JosephCDRE</p>
                    <button className="register-bttn mt-3" onClick={redirectToThirdPartyLink}>Register Here</button>
                </div>
                <div className='lunch'>
                    <h1> Breakfast or Lunch and Learn<br />
                    </h1>
                    <p><b> What:</b> A general overview of the services that we provide for our attorneys with breakfast and lunch provided for the attorneys and paralegals
                    </p>
                    <p className='mt-2'> <b> When:</b> 1-2 hours, at your convenience​</p>
                    <p className='mt-2'> <b>Where:</b> Your office​</p>
                    <p className='mt-2'> <b>Who:</b> Designed to educate your entire office; Presented by Joe Gordon</p>
                    <p className='mt-2'> <span><b>  Contact Us:</b> 801-577-6304 </span></p>
                </div>
            </div>
           
            <div>
                <CarouselScreen />
            </div>
            <OrderModals appraisal={appraisal} setAppraisal={setAppraisal} />
            <Request property={property} setProperty={setProperty} />
            <GetStarted showModal={showModal} setShowModal={setShowModal} />
        </section>
    )
}
export default Attorney;
