import React, { useState } from 'react';
import About from "../../assets/images/joe.jpg";
import "./AboutUs.css";
import { Link } from 'react-router-dom';
import Modals from '../../common/header/Modals';
import Hunnah from '../../assets/images/hunnah.jpg';
import Lesley from '../../assets/images/Lesley.jpg';
import Monique from '../../assets/images/monique.jpg';

const AboutUS = () => {
  window.scrollTo(0, 0);
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  return (
    <section className="about-us-container">
      <div className='about-us1'>
        <div className="text-center">
          <p className="about-us-text">About Us</p>
          <p className="team-purpose-text">A Team with Purpose: We Got Your Back </p>
          <Link to="" className="schedule-button" onClick={openModal}>SCHEDULE A CALL</Link>
        </div>
      </div>
      <div className='about-background'>
        <div className='container1'>
          <div className='about-joe'>
            <div className="row">
              <div className="col-md-4 col-sm-12">
                <div className='about-us'>
                  <img src={About} alt="about-us" className="img-fluid" />
                </div>
              </div>
              <div className="col-md-7 col-sm-12">
                <div className='text-aboutUs'>
                  <h2>Joe Gordon , Broker Owner</h2>
                  <p className='mt-4'><b>Joe</b> has been involved in residential real estate sales in Utah for the past 12 years and is currently the Principal Broker and owner of the Gordon Real Estate Group.</p>
                  <p className="mt-3">
                    As a Certified <b>Divorce Real Estate Expert (CDRE),</b> Joe works closely with family law attorneys, mediators, and their clients, assisting with the preparation and sale of the real estate involved in their cases.
                  </p>
                  <div className="text-center mt-4"></div>
                </div>
              </div>
            </div>

            <div class="Hannah mt-5">
              <div className="row">
                <div className="col-md-4 col-sm-12 order-sm-2">
                  <div className='about-us'>
                    <img src={Hunnah} alt="about-us" className="img-fluid" />
                  </div>
                </div>
                <div className="col-md-7 col-sm-12 order-sm-1">
                  <div className='text-aboutUs'>
                    <h2>Hannah DePasquale, Transaction Coordinator:</h2>
                    <p className='mt-4'>Hannah has worked with the Gordon Real Estate Group for the last 3 years and is the Transaction Coordinator for the team. Hannah manages all of our property sales from start to finish and is trained in working with family law attorneys, mediators, and judges.</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="moniqueeeeee mt-5">
              <div className="row">
                <div className="col-md-4 col-sm-12">
                  <div className='about-us'>
                  <img src={Monique} alt="about-us" className="img-fluid" />
                 
                  </div>
                </div>
                <div className="col-md-7 col-sm-12">
                  <div className='text-aboutUs'>
                    <h2 className="moniqueeeeee">Monique Rey, Marketing Manager:</h2>
                    <p className='mt-4'>Monique manages all of the marketing for the team and is in communication with the 170 family attorneys and mediators that we serve on a regular basis. In addition to planning and coordinating our client events, Monique also coordinates the Continuing Legal Education classes that we provide for our attorneys each month</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="lesley mt-5">
              <div className="row">
                <div className="col-md-4 col-sm-12 order-sm-2">
                  <div className='about-us'>
                  <img src={Lesley} alt="about-us" className="img-fluid" />
                  </div>
                </div>
                <div className="col-md-7 col-sm-12 order-sm-1">
                <div className='text-aboutUs'>
                <h2 className="moniqueeeeee">Lesley Uliberri, Escrow Officer:</h2>
                <p className='mt-4'>Lesley and her team manage all of the title work and closings for our client’s home sales. They work closely with our client’s Divorce Decrees to ensure that the orders are properly followed, the correct balances are paid off and that the parties are receiving the correct amount of funds at closing</p>
              </div>               
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div className="about-us-fair mt-5 text-center">
        <div className="container1">
          <h4 className="text-center expert mt-5">Real Estate experts</h4>
          <div className="text-center">
            <p className="joe-divorce mt-2">
              Joe is currently one of 3 Certified Divorce Real Estate experts in the state of Utah <br />and has expertise in the following areas:
            </p>
          </div>
        </div>
        <div className="container1">
          <div className='fair-maket-list mt-5'>
            <div className="row">
              <div className="col-md-4 col-sm-4">
                <div className="valuations">
                  <ul className="list-unstyled">
                    <li className="fair">Fair market Valuations</li> <br />
                    <li className="fair">Trained as an unbiased / neutral 3rd party</li> <br />
                    <li className="fair">Property preparation / staging</li>
                  </ul>
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="valuations">
                  <ul className="list-unstyled">
                    <li className="fair">Family Law Court Orders</li> <br />
                    <li className="fair">Restraining orders, protective orders, TRO’s</li> <br />
                    <li className="fair">Property marketing / advertisement</li>
                  </ul>
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="valuations">
                  <ul className="list-unstyled">
                    <li className="fair">High / Low conflict cases</li> <br />
                    <li className="fair">Expert witness testimony</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='mediator mt-5 text-center'>
        <p>If you are a family law attorney, mediator, or someone that is going through the</p>
        <p>divorce process and <span>needs expert assistance</span> with real estate,</p>
        <p><b>Please feel free to reach out</b></p>
      </div>
      <div className='centered-button-container text-center'>
        <button className='centered-button' onClick={openModal}>SCHEDULE A CALL</button>
      </div>
      <Modals showModal={showModal} setShowModal={setShowModal} />
    </section>
  )
}

export default AboutUS;

 