import React, { useEffect, useState } from 'react';
import './Program.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Modals from '../../common/header/Modals';
import Questions from './Questions/Questions';

const Program = () => {

    const [showModal, setShowModal] = useState(false);

    const openModal = () => {
        setShowModal(true);
    };

    useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);
        // Check if there is a hash in the URL
        const hash = window.location.hash.substring(1);
        if (hash === 'cost-advance-program') {
            const element = document.getElementById('cost-advance-program');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
        return () => {
        };
    }, []);

    return (
        <section>
            <div className='advance-program'>
                <div className='text-overlay1'>
                    <div className='centered-content'>
                        <h1>Home Preparation Program</h1>
                        <p className='mt-5'>Maximize your home’s value before it</p>
                        <p>hits the market.</p>
                        <div className='call-btn mt-4 preparing '>
                            <p >Preparing a home to go on the market can seem overwhelming and expensive.  Oftentimes homes are in need of a professional deep cleaning, repairs, carpet cleaning or landscaping cleanup before they can be properly marketed to buyers.  The costs for these services can add up quickly and unfortunately it is common for divorcing homeowners to not have the financial means to do what is needed.  </p>
                            <p > To ensure that the home is ready to go and that our clients get the best possible price, the Gordon Real Estate Group has partnered with service providers that will complete all the needed preparations up front, to then have their invoices fulfilled at the closing of the home sale. </p>
                            <button onClick={openModal} className="schedule-call-button mt-4">
                                SCHEDULE A CALL
                            </button>
                        </div>
                    </div>
                    <div className='feature-container'>
                        <h2 className='cost-advance mt-5'>Home Preparation</h2>
                        <p>GRG will create a home preparation plan for the homeowner.</p>
                    </div>
                    <div className='feature-container mt-5'>
                        <h2>Coordinate</h2>
                        <p>Our team will secure the best service professionals and handle all the details.</p>
                    </div>
                    <div className='feature-container mt-5'>
                        <h2>Profit</h2>
                        <p>All of the service providers are reimbursed at closing, while you keep all of the profits.</p>
                    </div>
                </div>
            </div>
    
            <div className='services'>
                <div className='covered-services'>
                    <h3>Covered Services</h3>
                    <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-md-6 col-sm-12'>
                            <div className='service-list'>
                                <ul className='cleanup'>
                                    <li>Landscaping cleanup</li>
                                    <li>Paint touch ups</li>
                                    <li>Carpet Cleaning</li>
                                    <li>Deep Cleaning</li>
                                    <li>Wall Patching</li>
                                </ul>
                            </div>
                        </div>

                        <div className='col-md-6 col-sm-12'>
                            <div className='service-list'>
                                <ul className='plumbing'>
                                    <li>Plumbing Repairs</li>
                                    <li>Mold Remediation</li>
                                    <li>Decluttering</li>
                                    <li>Staging</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Questions />
            <Modals showModal={showModal} setShowModal={setShowModal} />
        </section>
    );
};

export default Program;


