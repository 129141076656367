import React, { useEffect, useState } from 'react';
import '../Program.css';
import { Col, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Collapse from 'react-bootstrap/Collapse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

const Questions = () => {
    const [apiData, setApiData] = useState([]);
    const [openFirstColumn, setOpenFirstColumn] = useState(-1);

    const toggleCollapseFirstColumn = (index, event) => {
        event.preventDefault();
        setOpenFirstColumn((prevIndex) => (prevIndex === index ? -1 : index));
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                 const response = await fetch('https://utahdivorcerealestate.com/gordon/api/advance-programs');
                //  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/advance-programs`)
                const responseData = await response.json();
                if (Array.isArray(responseData.data)) {
                    setApiData(responseData.data);
                    // console.log(responseData.data, "responseData.data")
                } else {
                    console.error('API data does not contain an array:', responseData);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    return (
        <div className="collapse-list">
            <div className="question-section">
                <Row>
                    {apiData.map((item, index) => (
                        <Col key={index} md={6}>
                            <div className="question-container">
                                <p
                                    onClick={(event) => {
                                        event.preventDefault();
                                        toggleCollapseFirstColumn(index, event);
                                    }}
                                    aria-controls={`example-collapse-text-${index}`}
                                    aria-expanded={openFirstColumn === index}
                                    className={`toggle-text ${openFirstColumn === index ? 'open' : ''}`}
                                >
                                    <FontAwesomeIcon
                                        icon={openFirstColumn === index ? faMinus : faPlus}
                                        className="icon"
                                    />
                                    <b> {item.questions}</b>
                                </p>
                                <Collapse in={openFirstColumn === index}>
                                    <div id={`example-collapse-text-${index}`} className="answer mt-4">
                                        <p><b> {item.answers} </b></p>
                                    </div>
                                </Collapse>
                            </div>
                        </Col>
                    ))}
                </Row>
            </div>
        </div>
    );
};

export default Questions;
