
import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import '../../common/header/header.css';
import './Download.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Divorcing = ({ real, setReal }) => {


  const handleGetStarted = () => {
    // Close the modal
    setReal(false);

    // Open the URL in a new tab
    window.open('https://forms.gle/5h7WRqztyL6QbRqh6', '_blank');
  };


  return (
    <div>
      <div className="modal-container text-center">
        <Modal show={real} className="modail-container" onHide={() => setReal(false)} size="lg" centered>
          <div className='text-end'>
            <button onClick={() => setReal(false)}>X</button>
          </div>
          <Modal.Body>
            <p className='text-center' style={{ fontSize: "30px",}}>To help with accuracy,
              please take a few minutes to tell us a little more about your home</p>
              <div className='text-center'>
              <button className='get-started mt-5 bbbttnnnn' onClick={handleGetStarted}>
              Get Started
            </button>
              </div>
            
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default Divorcing;
