import React from "react";
import { Carousel } from "react-bootstrap";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import img1 from "../../assets/images/Testimonal1.jpg";
import img2 from "../../assets/images/Testimonal2.jpg";
import img3 from "../../assets/images/Testimonial3.jpg";
import img4 from "../../assets/images/Testimonial4.jpg";
import img5 from "../../assets/images/Testimonial5.jpg";

const data = [
  {
    img: img1,
    text: "Joe helped me with my home sale and also with my new home. He does a great job and negotiated great terms for me on both homes. Thanks Joe! – <strong style={{ fontWeight: 'bold' }}>Alex B</strong>.",
  },
  {
    img: img5,
    text: "Joe is a great agent, communicates well and knows what he is doing. I love my new condo and so does my fur baby😊. – <strong style={{ fontWeight: 'bold' }}>Debbie H</strong>.",
  },
  {
    img: img3,
    text: "I had a good experience with Joe’s team and am really happy with the result. They communicate every step of the way and I will refer him to everyone I know! – <strong style={{ fontWeight: 'bold' }}>Bob D</strong>.",
  },
  {
    img: img4,
    text: "Joe is an expert negotiator and saved me $10,000 on a sewer line that didn’t need to be replaced. I will always work with Joe. – <strong style={{ fontWeight: 'bold' }}>Andi G</strong>.",
  },
  {
    img: img2,
    text: "Joe was referred to me by my sister and was awesome to work with. Professional, hard working and knows what he is doing.  <strong style={{ fontWeight: 'bold' }}>Thanks Joe!</strong>",
  },
];

const CarouselScreen = () => {
  const iconStyle = {
    fontSize: '30px',
    color: "black",
  };

  return (
    <div className="container1 pt-4 mt-5">
      <h1 className="regular-font-heading color mt-4 client-success-stories">Client Success Stories</h1>
      <Carousel
        prevIcon={<ArrowBackIosNewIcon style={iconStyle} className="backiosnewicon" />}
        nextIcon={<ArrowForwardIosIcon style={iconStyle} className="forwardiosicon" />}
      >
        {data.map((item, index) => (
          <Carousel.Item key={index}>
            <div className="row carsel-row-box">
              <div className="col">
                <img src={item.img} alt={`Testimonial ${index + 1}`} className="carousell-img" />
              </div>
              <div className="col">
                <div className="text-left carousel-text-box">
                  <p className="regular-font color carousel-items" dangerouslySetInnerHTML={{ __html: item.text }} />
                </div>
              </div>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default CarouselScreen;

