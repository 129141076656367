import React from "react";
import { Row, Col } from "react-bootstrap";

const data = [
  {
    heading: "Neutrality",
    text: "Equal treatment between all clients — we don't pick sides.",
  },
  {
    heading: "Divorce Listing Process",
    text: "Customized for divorce listings so that problems are prevented. Local",
  },
  {
    heading: "Custom Marketing Strategy",
    text: "Marketing campaigns that place your property above the rest.",
  },
  {
    heading: "Monetary Assistance",
    text: "Cost Advance Program that advances improvement costs to be reimbursed at closing.",
  },
  {
    heading: "Communication System",
    text: "Everyone kept on the same page so no one is left in the dark.",
  },
  {
    heading: "Court Appointed",
    text: "When Court-appointed, we dutifully ensure all orders are followed.",
  },
  {
    heading: "Sale Sabotage Escalation",
    text: "If someone deviates from the plan, our escalation protocol gets things back on track.",
  },
  {
    heading: "Timeline",
    text: "You will know how long it will take to sell and when you will have to move.",
  },
];

const Multisection = () => {
  return (
    <div>
      <div className="multisection-about-text-box">
        <h5 className="multisection-about-text">It’s all about you...</h5>
        <p className="multisection-about-sub-text mt-5">
          You're at the center of everything we do. <br />We are obsessed with
          protecting the bottom line of divorcing homeowners, and closing the
          door to a <br/>painful experience —so that you can find your new place in
          the world.
        </p>
      </div>
      <Row className="w-100 mt-5 mb-5">
      
        {data.map((item, index) => {
          const isOdd = index % 2 === 0; // Check if the index is even
          const textColorClass = isOdd ? 'text-white' : 'text-black';

          return (
            <Col sm={3} md={3} key={index} className="multisection-sub-boxes">
              <h5 className={`multisection-heading m-4`}>{item.heading}</h5>
              <p className={`multisection-text ${textColorClass}`}>{item.text}</p>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default Multisection;
