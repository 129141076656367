import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { FaArrowUp, FaArrowDown, FaArrowRight } from 'react-icons/fa';
import '../../common/header/header.css';
import "./OrderModals.css";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const OrderModals = ({ appraisal, setAppraisal }) => {
  const [showOrderIntro, setShowOrderIntro] = useState(true);
  const [currentStep, setCurrentStep] = useState(1);
  const [firmName, setFirmName] = useState('');
  const [name, setName] = useState('');
  const [clientName, setClientName] = useState('');
  const [propertyType, setPropertyType] = useState('');
  const [address, setAddress] = useState('');
  const [document, setDocument] = useState([]);
  const [email, setEmail] = useState('');

  const [documentError, setDocumentError] = useState('');
  const [error, setError] = useState('');

  const documentList = [
    "Grant Deed",
    "Deed(s) of Trust",
    "Legal Description",
    "Chain of Title",
    "Property Profile"
  ];

  const handleOptionClick = (option) => {
    const updatedOptions = [...document];
    const optionIndex = updatedOptions.indexOf(option);

    if (optionIndex === -1) {
      updatedOptions.push(option);
    } else {
      updatedOptions.splice(optionIndex, 1);
    }

    setDocument(updatedOptions);
    setDocumentError(''); // Clear document error when an option is selected
  };

  const steps = [
    { title: '1', content: "Firm Name", input: firmName, setInput: setFirmName },
    { title: '2', content: "What is your name? ", input: name, setInput: setName },
    { title: '3', content: "Client Name ", input: clientName, setInput: setClientName },
    { title: '4', content: "Property Type ", input: propertyType, setInput: setPropertyType },
    { title: '5', content: "Street Address, City, State and Zip Code ", input: address, setInput: setAddress },
    { title: '6', content: "Complimentary Documents Requested ", input: document, setInput: setDocument },
    { title: '7', content: "What email address should we send this to?", input: email, setInput: setEmail },
  ];

  const handleCloseModal = () => {
    setAppraisal(false);
  };

  const handlePreviousStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleNextStep = () => {
    if (validateStep()) {
      if (currentStep < 7) {
        setCurrentStep(currentStep + 1);
      }
    }
  };

  const validateStep = () => {
    switch (currentStep) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
        const inputWords = steps[currentStep - 1].input.trim().split(/\s+/).filter(word => word !== '');
        if (steps[currentStep - 1].input.trim() === '') {
          setError(`${steps[currentStep - 1].content} is required.`);
          return false;
        } else if (inputWords.length <= 3 && steps[currentStep - 1].input.trim().length < 3) {
          setError(`${steps[currentStep - 1].content} must contain least 3 characters long.`);
          return false;
        }
        break;
      case 6:
        if (document.length === 0) {
          setDocumentError('At least one option must be selected.');
          return false;
        }
        break;
      case 7:
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (email.trim() === '') {
          setError(`${steps[currentStep - 1].content} is required.`);
          return false;
        } else if (!emailRegex.test(email.trim())) {
          setError('Please enter a valid email address.');
          return false;
        }
        break;
      default:
        break;
    }
    setError('');
    return true;
  };

  const handleSubmit = async () => {
    if (validateStep()) {
      if (currentStep < 7) {
        handleNextStep();
      } else {
        // Prepare the data for the API call
        const requestData = {
          firmName,
          name,
          clientName,
          propertyType,
          address,
          document,
          email,
        };

        try {
          // Make the API call
          const response = await fetch('https://utahdivorcerealestate.com/gordon/api/order', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Auth-Token': '0H2jTtterGuVLooOnwRbfHslly94SKnaLbBwfp6VxvIFZRt5sG0byyTqMyHNI0po',
            },
            body: JSON.stringify(requestData),
          });

          // Check if the request was successful (status code 2xx)
          if (response.ok) {
            // Handle the successful response, e.g., show a success message
            toast.success('Order submitted successfully!', { autoClose: 3000 });
            console.log('Order submitted successfully!');
          } else {
            // Handle the error response, e.g., show an error message
            console.error('Error submitting order:', response.statusText);
            toast.error('Error submitting order. Please try again.', { autoClose: 3000 });
          }
        } catch (error) {
          // Handle any network or other errors
          console.error('Error:', error.message);
          toast.error('Error submitting order. Please try again.', { autoClose: 3000 });
        }

        handleCloseModal();
      }
    }
  };

  const handleGetStarted = () => {
    setShowOrderIntro(false);
  };

  useEffect(() => {
    if (!appraisal) {
      setShowOrderIntro(true);
      setCurrentStep(1);
      setFirmName('');
      setName('');
      setClientName('');
      setPropertyType('');
      setAddress('');
      setDocument([]);
      setEmail('');
      setError('');
    }
  }, [appraisal]);

  const handleClose =()=> {
    handleCloseModal();
  }
  return (
    <div>
    <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <div className="modail-container text-center">
        <Modal show={appraisal} className="modail-container" onHide={handleCloseModal} size="lg" centered>
       
        <div className='text-end'>
        <button   onClick={handleClose}>X</button>
        </div>
        <Modal.Body>
            {showOrderIntro ? (
              <div className='oder-modal-content'>
                <h3 className='text-center realpropertyyydoc'>
                  Real Property Documents
                </h3>
                <p className='mt-4 ownershippp'>
                  Establishing ownership and encumbrances is an essential step in every family law matter with real property.
                </p>
                <p className="ownershippp">
                  Whether it’s current vesting deeds, deeds of trust, lis pendens, legal descriptions, chain of title, or any other recorded documents, GRG will provide you everything you need regarding real property title recordings in your cases.
                </p>
                <p className="ownershippp">
                  This is a complimentary service, and our estimated turn-around time is within one business day.
                </p>
                <div className="startedgetbtnn">
                  <Button className='get-started mt-5 buttonstartget' onClick={handleGetStarted}>
                    Get Started
                  </Button>
                </div>
              </div>
            ) : (
              <Form>
                <Form.Group>
                  <span className="fontttdesignn">{currentStep}</span>
                  <FaArrowRight className="step-arrow arrowdesignnnn" />
                  <Form.Label className='from-label'>{steps[currentStep - 1].content}</Form.Label>

                  {currentStep === 6 ? (
                    <div className="document-options-row mt-5">
                      {documentList.map((option, index) => (
                        <>
                          <Button
                            key={index}
                            className={`document-option-btn deadddbttnn ${document.includes(option) ? 'selected' : ''}`}
                            onClick={() => handleOptionClick(option)}
                            style={{ backgroundColor: document.includes(option) ? '#C3AA91' : '' }}
                          >
                            {option}
                          </Button>

                        </>
                      ))}
                    </div>
                  ) : (
                    <Form.Control
                      className='header-form-inputs'
                      type={currentStep === 7 ? "email" : "text"} // Use email type for Step 7
                      placeholder={`Enter ${currentStep === 7 ? 'email address' : `your ${currentStep === 1 ? 'firm name' : currentStep === 2 ? ' your name ' : (currentStep === 3 ? 'Client Name ' : currentStep === 4 ? 'Property Type' : 'Street Address, City, State and Zip Code ')}`}`}
                      value={steps[currentStep - 1].input}
                      onChange={(e) => {
                        steps[currentStep - 1].setInput(e.target.value);
                        setError(''); // Clear error when input changes
                      }}
                      isInvalid={error !== ''}
                    />
                  )}
                  <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
                </Form.Group>
                <Form.Text className="text-danger">{documentError}</Form.Text>
                {currentStep !== 7 && (
                  <Button className='modal-btn modal-ok-btn bttnnookkk' onClick={handleSubmit} disabled={error !== ''}>
                    OK
                  </Button>
                )}
                {currentStep === 7 && (
                  <Button className='modal-btn modal-ok-btn buttonsubbmitt' onClick={handleSubmit} disabled={error !== ''}>
                    Submit
                  </Button>
                )}
              </Form>
            )}
          </Modal.Body>
          {!showOrderIntro && (
            <Modal.Footer className="p-2">
              <Button onClick={handlePreviousStep} className={`footer-icon ${currentStep > 1 ? 'animate-up' : ''}`} disabled={currentStep === 1}>
                <FaArrowUp />
              </Button>
              <Button onClick={handleNextStep} className="footer-icon" disabled={currentStep === 7 || error !== ''}>
                <FaArrowDown />
              </Button>
            </Modal.Footer>
          )}
        </Modal>
      </div>
    </div>
  );
};

export default OrderModals;
