import React from 'react';
import { Link } from 'react-router-dom';
import "./NotFound.css"

function NotFound() {
  return (
    <section className="mt-5">
      <div className="w-100 d-flex justify-content-center align-items-center">
        <div className="col-md-12 text-center">
          <h1 className="error-page pageeserrorss" style={{ marginTop: "100px" }}>
            404
          </h1>
          <div className="four_zero_four_bg"></div>
          <div>
            <h3 className="h2 mt-2 nottfounddd">Looks like you're lost</h3>
            <p className="lookingpageee">the page you're looking for is not found!</p>
            <Link
              to="/"
              className="btn rounded-pill mb-4 error-homepage homepageeeerrorr">
              Go to the homepage
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default NotFound;
