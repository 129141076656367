import React from "react";

const data = [
  {
    heading: "FINANCES",
    text: "Making a decision on the house is the first step to your financial future.",
  },
  {
    heading: "EMOTIONS",
    text: "Getting out of limbo allows you to protect your mental sanity.",
  },
  {
    heading: "CHILDREN",
    text: "For kids, the house represents the next step for school, friends, and their new normal.",
  },
  {
    heading: "CLOSURE",
    text: "Without handling the house, you can't move on. The house is closure.",
  },
];

const Categories = () => {
  return (
    <div className="categories-section d-flex justify-content-center align-items-center">
      <div className="container categories-section-p">
        <div>
          <p className="categories-heading text-center">Your House Holds <br />the Key to Unlocking Your Future​​</p>
        </div>
        <div>
          <ul className="categories-list mt-4">
            {data.map((item, index) => {
              return (
                <li key={index}>
                  <h5 className="categories-list-heading">{item.heading}</h5>
                  <p className="categories-text">{item.text}</p>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Categories;
