import React, { useState } from "react";
import Modals from "../../common/header/Modals";


const Homepage = () => {

  const [showModal, setShowModal] = useState(false);
  const [hovered, setHovered] = useState(false);
  const openModal = () => {
      setShowModal(true);
  };

  const buttonStyles = {
    backgroundColor: "white",
    color: "black",
    border: "none",
    padding: "10px 20px",
    fontSize: "16px",
    cursor: "pointer",
    transition: "background-color 0.3s, color 0.3s",
  };

  const hoverStyles = {
    backgroundColor: "#77634d",
    color: "white",
  };
  return (
    <section>
      <div className="homepage-screen">
        <div className="homepage-screen-sub-conatiner">
          <div className="homepage-content-container">
            <h1 className="homepage-content-text-services">
            Real Estate Services  for  <br /> 
           Divorcing Homeowners
            </h1>  
            <button
            className="homepage-schedule-btn mt-5"
            style={{ ...buttonStyles, ...(hovered ? hoverStyles : {}) }}
            onClick={openModal}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
          >
            SCHEDULE A CALL
          </button>
          </div>
        </div>
      </div>
      <Modals showModal={showModal} setShowModal={setShowModal} />
    </section>
  );
};
export default Homepage;
