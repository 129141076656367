import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { FaBars } from "react-icons/fa";
import logo from "../../assets/images/logo.png";
import "./header.css";
import Modals from "./Modals";

const StyledHeader = styled.header`
  background-color: ${(props) => (props.isScrolled ? "#897764" : "#67503d")};
  width: 100%;
  padding: 10px 12px 8px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  z-index: 1000;
  transition: background-color 0.3s;

  .nav_logo {
    padding: 0 12px;
    .nav-logo-link {
      text-decoration: none;
      font-size: 24px;
      color: #fab005;
      font-weight: bold;
    }
  }

  .menuToggleBtn {
    display: none;
    color: white;
    font-size: 24px;
    position: absolute;
    right: 20px;
    top: 15px;
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    .menuToggleBtn {
      display: block;
    }
  }
`;

const NavManu = styled.ul`
  list-style: none;
  display: flex;

  li {
    &:hover {
      cursor: pointer;
      background: #a88d72;
      border-radius: 4px;
    }
  }
  .nav-menu-list {
    text-decoration: none;
    color: white;
    display: block;
    padding: 10px 20px;

    @media screen and (max-width: 768px) {
      padding: 10px 0px; /* Add padding for mobile screens */
    }
  }
  @media screen and (max-width: 768px) {
    display: ${(props) => (props.isToggleOpen ? "block" : "none")};
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 5px;
  }
`;

const Header = () => {
  const [isToggleOpen, setIsToggleOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [buttonTextColor, setButtonTextColor] = useState("black");
  const [isHovered, setIsHovered] = useState(false);
  const [buttonBackground, setButtonBackground] = useState("white");

  const handleToggleOpen = () => {
    setIsToggleOpen(!isToggleOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setIsScrolled(scrollTop > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  const handleCloseToggle = () => {
    setIsToggleOpen(false);
  };
  const scheduleBtnStyles = {
    color: buttonTextColor,
    border: "1px solid white",
    marginLeft:"20px",
    whiteSpace: "nowrap",
    backgroundColor: buttonBackground,
    transition: "background-color 0.3s, color 0.3s",
    padding: "9px 26px 7px 18px",
   
  };

  const handleHover = (hovered) => {
    setIsHovered(hovered);
    setButtonBackground(hovered ? "#77634d" : "white");
    setButtonTextColor(hovered ? "white" : "black");
  };
  const handleShowModal = () => {
    setShowModal(true);
    setCurrentStep(1);
  };
  const hoverStyles = {
    backgroundColor: "#77634d",
    color: "white",
  };
  return (
    <>
      <StyledHeader isScrolled={isScrolled}>
        <div className="nav_logo">
          <Link to={"/"} className="nav-logo-link">
            <img height={50} width={50} src={logo} alt="logo" />
          </Link>
        </div>
        <NavManu isToggleOpen={isToggleOpen}>
          <li>

            <Link to={"/"} className="nav-menu-list" onClick={handleCloseToggle}>
              Home
            </Link>
          </li>
          <li>
            <Link to={"/about-us"} className="nav-menu-list" onClick={handleCloseToggle}>
              About US
            </Link>
          </li>
          <li>
            <Link to={"/attorney-resources"} className="nav-menu-list" onClick={handleCloseToggle}>
              Attorney Resources
            </Link>
          </li>
          <li>
            <Link to={"/access-resources"} className="nav-menu-list" onClick={handleCloseToggle}>
              Client  Resources
            </Link>
          </li>
          <li>
            <Link to={"/home-prep-program"} className="nav-menu-list" onClick={handleCloseToggle}>
            Home Prep Program
            </Link>
          </li>

          <li className="nav-menu-list">
            <button
          
              style={{
                ...scheduleBtnStyles,
                ...(isHovered ? hoverStyles : {}),
              
              }}
              onMouseEnter={() => handleHover(true)}
              onMouseLeave={() => handleHover(false)}
              onClick={handleShowModal}
            >
              SCHEDULE A CALL
            </button>
          </li>

        </NavManu>
        <FaBars className="menuToggleBtn" onClick={handleToggleOpen} />
        <Modals showModal={showModal} setShowModal={setShowModal} />
      </StyledHeader>
    </>
  );
};

export default Header;
