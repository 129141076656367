import React from "react";

const Group = () => {
  return (
    <div className="group-section d-flex justify-content-center align-items-center">
      <div className="container">
        <p className="w-100 regular-font-heading text-center mb-60">Why Choose Starks Realty Group?</p>
        <div className="row mt-5">
          <div className="col-sm">
            <div className="d-flex">
              <div className="p-20">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="60"
                  height="60"
                  viewBox="0 0 77 77"
                  fill="none"
                >
                  <circle cx="38.055" cy="38.5839" r="37.985" fill="white" />
                  <path
                    d="M61.7273 26.568L54.2882 19.1289L30.4196 42.9975L21.8229 34.4007L14.3838 41.8398L30.5561 58.0307L61.7273 26.568Z"
                    fill="#C4AC93"
                  />
                </svg>
              </div>
              <div className="text-left">
                <h4 className="regular-font-heading">Unparalleled Experience</h4>
                <p className="regular-font">
                  No one has more experience than we do in divorce real estate.
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm">
            <div className="d-flex">
              <div className="p-20">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="60"
                  height="60"
                  viewBox="0 0 77 77"
                  fill="none"
                >
                  <circle cx="38.055" cy="38.5839" r="37.985" fill="white" />
                  <path
                    d="M61.7273 26.568L54.2882 19.1289L30.4196 42.9975L21.8229 34.4007L14.3838 41.8398L30.5561 58.0307L61.7273 26.568Z"
                    fill="#C4AC93"
                  />
                </svg>
              </div>
              <div className="text-left">
                <h4 className="regular-font-heading">Competitive Rates</h4>
                <p className="regular-font">
                  Using a sliding scale, our fee structures are affordable and
                  customized for every case.
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm">
            <div className="d-flex">
              <div className="p-20">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="60"
                  height="60"
                  viewBox="0 0 77 77"
                  fill="none"
                >
                  <circle cx="38.055" cy="38.5839" r="37.985" fill="white" />
                  <path
                    d="M61.7273 26.568L54.2882 19.1289L30.4196 42.9975L21.8229 34.4007L14.3838 41.8398L30.5561 58.0307L61.7273 26.568Z"
                    fill="#C4AC93"
                  />
                </svg>
              </div>
              <div className="text-left">
                <h4 className="regular-font-heading">You're in Great Hands</h4>
                <p className="regular-font">
                  We know how important this is to you. Your family is treated
                  like it's our own.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Group;
