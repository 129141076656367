import React from "react";
import { Row, Col } from "react-bootstrap"
import img from "../../assets/images/circle.png";
// import lineimg from "../../assets/images/Group648.png"

const data = [
  { count: "01", text: "Stop the Bleeding" },
  { count: "02", text: "Build a Timeline" },
  { count: "03", text: "Estimate Your Net Proceeds" },
  { count: "04", text: "Let Us Shoulder the Stress" },
  { count: "05", text: "Start Your Life’s 2.0" },
];
const Futuregoals = () => {
  return (
    <div className="container-fluid">
      <div className="img-conatiner">
        <p className="img-sub-conatiner">
          <img src={img} alt="" />
        </p>
      </div>
      <div className="futuregoal-container col-12">
        <h5 className="futuregoal-heading ">Get Control of Your Future</h5>
        <p className="futuregoal-subheading">
          Where are you going to live? <br /> What will your new routine be like? <br /> How
          will you afford to feel secure again?
        </p>
        <p className="futuregoal-heading-text">It’s time to get some answers.​</p>

      </div>
      <div className="futuregoals-line-img ">
      </div>
      <Row className="futuregoal-container">
        <Col sm={12} md={12}>
          <ul className="futuregoals-list-conatiner">
            {data.map((item, index) => {
              return (
                <li key={index}>
                  <h5 className="futuregoals-list-count">{item.count}</h5>
                  <p className="futuregoals-list-text">{item.text}</p>
                </li>
              );
            })}
          </ul>
        </Col>
      </Row>
    </div>
  );
};
export default Futuregoals;
