import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { FaArrowUp, FaArrowDown, FaArrowRight } from 'react-icons/fa';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { useDropzone } from 'react-dropzone';
import '../../common/header/header.css';
import "./Request.css"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";


const Modals = ({ property, setProperty }) => {
  // const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const selectedOptionColor = '#C3AA91';
  const [currentStep, setCurrentStep] = useState(1);
  const [name, setName] = useState('');
  const [firmName, setFirmName] = useState('');
  const [phone, setPhone] = useState('');
  const [caseName, setCaseName] = useState('');
  const [clientName, setClientName] = useState('');
  const [orderForAppraisal, setOrderForAppraisal] = useState('');
  const [orderDoc, setOrderDoc] = useState(null);
  const [propertyAddress, setPropertyAddress] = useState('');
  const [reportDueDate, setReportDueDate] = useState(null);
  const [typeOfReport, setTypeOfReport] = useState('');
  const [email, setEmail] = useState('');
  const [content, setContent] = useState('');
  const [requestIntro, setRequestIntro] = useState(true);
  const [validationErrorStep1, setValidationErrorStep1] = useState('');
  const [validationErrorStep2, setValidationErrorStep2] = useState('');
  const [validationErrorStep3, setValidationErrorStep3] = useState('');
  const [validationErrorStep4, setValidationErrorStep4] = useState('');
  const [validationErrorStep5, setValidationErrorStep5] = useState('');
  const [validationErrorStep6, setValidationErrorStep6] = useState('');
  const [validationErrorStep7, setValidationErrorStep7] = useState('');
  const [validationErrorStep8, setValidationErrorStep8] = useState('');
  const [validationErrorStep9, setValidationErrorStep9] = useState('');
  // const [selectedOptionColor, setSelectedOptionColor] = useState('');
  const [imageValidationError, setImageValidationError] = useState('');
  const [validationErrorStep10, setValidationErrorStep10] = useState('');
  const [validationErrorStep11, setValidationErrorStep11] = useState('');
  const [validationErrorStep12, setValidationErrorStep12] = useState('');

  const steps = [
    { title: '1', content: "What's your name?", input: name, setInput: setName },
    { title: '2', content: "What is the name of the firm you are with?", input: firmName, setInput: setFirmName },
    { title: '3', content: "What is the best contact number for you? ", input: phone, setInput: setPhone },
    { title: '4', content: "What is the case name?", input: caseName, setInput: setCaseName },
    { title: '5', content: "What is your client’s name?", input: clientName, setInput: setClientName },
    { title: '6', content: "Is there an order for an appraisal on the property?", input: orderForAppraisal, setInput: setOrderForAppraisal },
    { title: '7', content: "You may upload the order here.", input: orderDoc, setInput: setOrderDoc },
    { title: '8', content: "What is the property address?", input: propertyAddress, setInput: setPropertyAddress },
    // { title: '9', content: "What date is the report due?", input: { month: step9Month, day: step9Day, year: step9Year }, setInput: { month: setStep9Month, day: setStep9Day, year: setStep9Year } },
    {
      title: '9',
      content: 'What date is the report due?',
      input: reportDueDate,
      setInput: setReportDueDate,
    },
    { title: '10', content: "Please select the type of report needed:", input: typeOfReport, setInput: setTypeOfReport },
    { title: '11', content: "To what email address(es) shall we send the report? (Include client email addresses and/or opposing counsel, if applicable)", input: email, setInput: setEmail },
    { title: '12', content: "Is there anything else we should know?", input: content, setInput: setContent },
  ];

  const { getInputProps } = useDropzone({
    accept: 'image/*',
    maxSize: 20 * 1024 * 1024, // 20MB
    onDrop: (acceptedFiles) => {
      // Check if there are accepted files
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        // Check if the file size is within the allowed limit
        if (file.size <= 20 * 1024 * 1024) {
          setOrderDoc(file);
          setImageValidationError(''); // Clear any previous error messages
        } else {
          // Set an error message if the file size exceeds the limit
          setImageValidationError('Image size must be less than 20MB');
        }
      } else {
        // Set an error message if no files are accepted
        setImageValidationError('Image is required');
      }
    },
  });

  const handleCloseModal = () => {
    setProperty(false);
  };

  const handlePreviousStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const isValidDate = (day, month, year) => {
    return (
      /^\d{1,2}$/.test(day) &&
      /^\d{1,2}$/.test(month) &&
      /^\d{4}$/.test(year) &&
      parseInt(month, 10) >= 1 &&
      parseInt(month, 10) <= 12 &&
      parseInt(day, 10) >= 1 &&
      parseInt(day, 10) <= new Date(year, month, 0).getDate()
    );
  };

  const handleNextStep = () => {
    if (currentStep < 12) {
      // Validate based on current step
      switch (currentStep) {
        case 1:
          if (name.trim().length < 3) {
            setValidationErrorStep1("Please enter at least 3 characters for step 1.");
            return;
          }
          setValidationErrorStep1('');
          break;
        case 2:
          if (firmName.trim().length < 3) {
            setValidationErrorStep2("Please enter at least 3 characters for step 2.");
            return;
          }
          setValidationErrorStep2('');
          break;

        case 3:
          const phoneNumber = /^\+(?:[0-9] ?){9,11}[0-9]$/;
          if (!phoneNumber || !phoneNumber.test(phone.replace(/[^0-9+]/g, ''))) {
            setValidationErrorStep3(`Phone number must be a valid  phone number.`);
            return false;
          }

          break;
        case 4:
          if (caseName.trim().length < 3) {
            setValidationErrorStep4("Please enter a valid case .");
            return;
          }
          setValidationErrorStep4('');
          break;
        case 5:
          if (clientName.trim().length < 3) {
            setValidationErrorStep5("Please enter a valid client name .");
            return;
          }
          setValidationErrorStep5('');
          break;
        case 6:
          if (!orderForAppraisal) {
            setValidationErrorStep6("Please select either 'Yes' or 'No'.");
            return;
          }
          setValidationErrorStep6('');
          break;
        case 7:
          if (!orderDoc) {
            setImageValidationError('Image is required');
            return;
          }
          setImageValidationError(''); // Clear any previous error messages
          break;
        case 8:
          if (propertyAddress.trim().length < 10) {
            setValidationErrorStep8("Please enter a valid  property Address .");
            return;
          }
          setValidationErrorStep8('');
          break;
        case 9:
          if (!reportDueDate) {
            setValidationErrorStep9('Please enter a valid date .');
            return;
          }
          setValidationErrorStep9('');
          break;

        case 10:
          if (selectedOptions.length === 0) {
            setValidationErrorStep10('Please select at least one option.');
            return;
          }
          setValidationErrorStep10('');
          break;

        case 11:
          // Validate email addresses
          const emailAddresses = email
            .split(',')
            .map((email) => email.trim())
            .filter((email) => email !== '');

          // Ensure at least one email address is required
          if (emailAddresses.length === 0) {
            setValidationErrorStep11('Please enter at least one email address.');
            return;
          }

          // Validate each email address
          const isInvalidEmail = emailAddresses.some((email) => !isValidEmail(email));
          if (isInvalidEmail) {
            setValidationErrorStep11('Please enter valid email addresses.');
            return;
          }

          setValidationErrorStep11('');
          break;
        case 12:
          console.log('Step 12 Input Length:', content.trim().length); // Add this line for debugging
          if (content.trim().length < 15) {
            setValidationErrorStep12("Please enter at least 15 characters.");
            return;
          }
          setValidationErrorStep12('');
          break;
      }
      // Check if the current step has validation errors
      const validationErrorState = `validationErrorStep${currentStep}`;
      if (eval(validationErrorState)) {
        return;
      }

      setCurrentStep(currentStep + 1);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setOrderDoc(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (currentStep < 12 && e.target.className.includes('okkkkbtnnn')) {
      setTimeout(() => {
        handleNextStep();
      }, 0);
    } else if (currentStep === 12 && e.target.className.includes('submittbuttonnn')) {
      const requestData = {
        name,
        firmName,
        phone,
        caseName,
        clientName,
        orderForAppraisal,
        orderDoc,
        propertyAddress,
        reportDueDate,
        typeOfReport: selectedOptions,
        email,
        content,
      };

      const formData = new FormData();
      Object.entries(requestData).forEach(([key, value]) => {
        formData.append(key, value);
      });
      try {
        const response = await fetch('https://utahdivorcerealestate.com/gordon/api/property-appraisal', {
          method: 'POST',
          headers: {
            'Auth-Token': '0H2jTtterGuVLooOnwRbfHslly94SKnaLbBwfp6VxvIFZRt5sG0byyTqMyHNI0po',
          },
          body: formData,
        });
        if (response.ok) {
          // Successful API call, you can handle the response here
          console.log('API call successful:', response);
          toast.success('Data submitted successfully!', { autoClose: 3000 });
          handleCloseModal(); // Close the modal or perform other actions
        } else {
          // Handle API error
          console.error('API call failed:', response);
          toast.error('Error submitting data. Please try again.', { autoClose: 3000 });
          // You can show an error message to the user or take other appropriate actions
        }
      } catch (error) {
        // Handle network errors or other exceptions
        console.error('An error occurred during the API call:', error);
        toast.error('Error submitting data. Please try again.', { autoClose: 3000 });
        // You can show an error message to the user or take other appropriate actions
      }
    }
  };

  const isValidEmail = (email) => {
    return email.includes('@');
  };
  const openFileUpload = () => {
    document.getElementById('fileInput').click();
  };

  const handleGetStarted = () => {
    setRequestIntro(false);
  };

  useEffect(() => {
    if (!property) {
      setRequestIntro(true);
      setCurrentStep(1);
      setName('');
      setFirmName('');
      setPhone('');
      setCaseName('');
      setClientName('');
      setOrderForAppraisal('');
      setOrderDoc(null);
      setPropertyAddress('');
      setReportDueDate('');
      setTypeOfReport('');
      setEmail('');
      setContent('');
    }
  }, [property]);

  const toggleOption = (option) => {
    const updatedOptions = [...selectedOptions];
    if (updatedOptions.includes(option)) {
      // Remove option if already selected
      updatedOptions.splice(updatedOptions.indexOf(option), 1);
    } else {
      // Add option if not selected
      updatedOptions.push(option);
    }
    setSelectedOptions(updatedOptions);
  };

  const handleClose = () => {
    handleCloseModal();
  }

  return (
    <div>
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <div className="modail-container text-center">
        <Modal show={property} className="modail-container" onHide={handleCloseModal} size="lg" centered>
          <div className='text-end'>
            <button onClick={handleClose}>X</button>
          </div>
          <Modal.Body>
            {requestIntro ? (
              <div className='oder-modal-content'>
                <h3 className='text-center appraisalproperty'>
                  Real Property Appraisal</h3>
                <p className='mt-5 obtainingappraisal'> Obtaining an appraisal is crucial to establishing
                  a reliable value for real property.
                </p>
                <p className='mt-5 obtainingappraisal'>Whether you need current, historic, fair market rental,
                  Moore Marsden values, and expert testimony, GRG and
                  our network of certified appraisers will get you
                  exactly what you need for your case.</p>
                <div className="getstartedddbtnnn">
                  <button className='get-started mt-5 getttttbtnnn' onClick={handleGetStarted}>
                    Get Started
                  </button>
                </div>
              </div>
            ) : (
              <Form>
                <Form.Group>
                  <span className={currentStep === 11 ? 'step-11-span' : 'fontdesignings'}>{currentStep}</span>
                  <FaArrowRight className={currentStep === 11 ? 'step-11-arrow arrowdesigns11' : 'step-arrow arrowdesignss'} />
                  <Form.Label className={currentStep === 11 ? 'step-11-label' : 'from-label'}>
                    {steps[currentStep - 1].content}
                  </Form.Label>
                  {currentStep === 3 ? (
                    <PhoneInput
                      style={{ marginRight: '10px' }}
                      placeholder="Enter phone number"
                      value={steps[currentStep - 1].input}
                      onChange={(value) => {
                        steps[currentStep - 1].setInput(value);
                        // Clear step 2 error when input changes
                        setValidationErrorStep3('');
                      }}
                      defaultCountry="US"
                      className={`header-form-inputs ${validationErrorStep3 !== '' ? 'is-invalid' : ''}`}
                    />
                  ) : currentStep === 11 ? (
                    <>
                      <Form.Control
                        className='header-form-inputs'
                        type="text"
                        placeholder="Enter email addresses"
                        value={steps[currentStep - 1].input}
                        onChange={(e) => steps[currentStep - 1].setInput(e.target.value)}
                      />
                      <span className={currentStep === 11 ? 'error-message' : 'hidden-error-message'}>
                        {validationErrorStep11}
                      </span>
                    </>
                  ) : currentStep === 7 ? (
                    <div className='modals-btn mt-5 imaaggeeuploadd'>
                      {orderDoc && (
                        <img
                          src={URL.createObjectURL(orderDoc)}
                          alt="Uploaded"
                          className="uploaded-image "
                          onClick={openFileUpload}
                        />
                      )}
                      <div className="dropzone" onClick={openFileUpload}>
                        <input {...getInputProps()} onChange={handleFileChange} id="fileInput" style={{ display: 'none' }} />
                        {!orderDoc && (
                          <p className="clicckkimggg">Click on the image to upload or drag and drop a new one</p>
                        )}
                        {imageValidationError && (
                          <span className="text-danger">{imageValidationError}</span>
                        )}
                      </div>
                    </div>
                  ) : currentStep === 9 ? (
                    <div className='modals-btn mt-5'>
                      <div className='datepicker-container'>
                        <DatePicker
                          ariaLabelledBy="sabu"
                          autoComplete="ac"
                          autoFocus={false}
                          isClearable={true}
                          clearButtonTitle="Clear"
                          selected={reportDueDate}
                          onChange={(date) => {
                            setReportDueDate(date);
                          }}
                        />
                      </div>
                      <span className="error-message">{validationErrorStep9}</span>
                    </div>
                  ) : currentStep === 6 ? (
                    <div className='modals-btn mt-5' >
                      <button
                        className={`yessbtnnnn ${orderForAppraisal === 'Yes' ? 'selected' : ''}`}
                        type="button"
                        onClick={() => steps[currentStep - 1].setInput('Yes')}
                      >
                        Yes
                      </button>
                      <br />
                      <button
                        className={`nooobtnnnn ${orderForAppraisal === 'No' ? 'selected' : ''}`}
                        type="button"
                        onClick={() => steps[currentStep - 1].setInput('No')}
                      >
                        No
                      </button>
                      {currentStep === 6 && <span className="error-message">{validationErrorStep6}</span>}
                    </div>
                  ) : currentStep === 10 ? (
                    <div className='modals-btn mt-5'>
                      <Button
                        className={`btnnnvaluee ${selectedOptions.includes('Current Fair Market Value') ? 'selected' : ''}`}
                        variant='light'
                        style={{ backgroundColor: selectedOptions.includes('Current Fair Market Value') ? selectedOptionColor : '' }}
                        onClick={() => toggleOption('Current Fair Market Value')}
                      >
                        Current Fair Market Value
                      </Button>
                      <br />
                      <Button
                        className={`btnnnvaluee ${selectedOptions.includes('Historic Fair Market Value(s)') ? 'selected' : ''}`}
                        variant='light'
                        style={{ backgroundColor: selectedOptions.includes('Historic Fair Market Value(s)') ? selectedOptionColor : '' }}
                        onClick={() => toggleOption('Historic Fair Market Value(s)')}
                      >
                        Historic Fair Market Value(s)
                      </Button>
                      <br />
                      <Button
                        className={`btnnnvaluee ${selectedOptions.includes('Current Fair Market Rental Value') ? 'selected' : ''}`}
                        variant='light'
                        style={{ backgroundColor: selectedOptions.includes('Current Fair Market Rental Value') ? selectedOptionColor : '' }}
                        onClick={() => toggleOption('Current Fair Market Rental Value')}
                      >
                        Current Fair Market Rental Value
                      </Button>
                      <br />
                      <Button
                        className={`btnnnvaluee ${selectedOptions.includes('Historic Fair Market Rental Value(s)') ? 'selected' : ''}`}
                        variant='light'
                        style={{ backgroundColor: selectedOptions.includes('Historic Fair Market Rental Value(s)') ? selectedOptionColor : '' }}
                        onClick={() => toggleOption('Historic Fair Market Rental Value(s)')}
                      >
                        Historic Fair Market Rental Value(s)
                      </Button>
                    </div>
                  ) : (
                    <Form.Control
                      className='header-form-inputs'
                      type="text"
                      placeholder={`Enter your ${currentStep === 1 ? 'name' : currentStep === 2 ? 'firm name ' : (currentStep === 3 ? 'phone number' : 'discussion topic')}`}
                      value={steps[currentStep - 1].input}
                      onChange={(e) => steps[currentStep - 1].setInput(e.target.value)}
                    />
                  )}

                  <span className={currentStep === 1 ? 'error-message' : 'hidden-error-message'}>{validationErrorStep1}</span>
                  <span className={currentStep === 2 ? 'error-message' : 'hidden-error-message'}>{validationErrorStep2}</span>
                  {currentStep === 3 && <span className="error-message">{validationErrorStep3}</span>}
                  {currentStep === 4 && <span className="error-message">{validationErrorStep4}</span>}
                  {currentStep === 5 && <span className="error-message">{validationErrorStep5}</span>}
                  <span className="error-message">{validationErrorStep8}</span>
                  <span className={currentStep === 10 ? 'error-message' : 'hidden-error-message'}>{validationErrorStep10}</span>

                </Form.Group>
                {(currentStep !== 12) && (
                  <button className='modal-btn modal-ok-btn okkkkbtnnn' onClick={(e) => handleSubmit(e)}>
                    OK
                  </button>
                )}
                {currentStep === 12 && (
                  <div>
                    <span className="error-message">
                      {content.trim().length > 0 && content.trim().length < 15
                        ? "Please enter at least 15 characters for Step 12."
                        : ''}
                    </span>
                    <button
                      className='modal-btn modal-ok-btn submittbuttonnn'
                      onClick={(e) => handleSubmit(e)}
                      disabled={content.trim().length < 15}
                    >
                      Submit
                    </button>
                  </div>
                )}
              </Form>
            )}

          </Modal.Body>
          {!requestIntro && (
            <Modal.Footer className="p-2">
              <Button onClick={handlePreviousStep} className={`footer-icon ${currentStep > 1 ? 'animate-up' : ''}`} disabled={currentStep === 1 ? true : ""}>
                <FaArrowUp />
              </Button>
              <Button onClick={handleNextStep} className="footer-icon" disabled={currentStep === 12 ? true : ""}>
                <FaArrowDown />
              </Button>
            </Modal.Footer>
          )}
        </Modal>
      </div>
    </div>
  );
};

export default Modals;

