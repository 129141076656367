import React from 'react';
import Group from './starkgroup';
import CarouselScreen from './carousel';
import Teamsection from './teamsection';
import Successstories from './successstories';
import Multisection from './multisection';
import Businesssection from './businesssection';
import Categories from './categories';
import Futuregoals from './futuregoals';
import Homepage from './homepage';

const Landing = () => {
    window.scrollTo(0, 0);
    return (
       <div>
        <Homepage/>
        <Futuregoals/>
        <Categories/>
        <Businesssection/>
        <Multisection/>
        <Successstories/>
        <Teamsection/>
        <CarouselScreen/>
        <Group/>
       </div>
    )
}
export default Landing;